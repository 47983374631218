import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import { LeftSection } from "../components/left-section/LeftSection";
import "./ForgotPassword.scss";
import environment from "../../config/environment";
import { TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(" ");
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate();
  const sendResetLink = () => {
    navigate("/verify-account");
  };

  const [buttonState, setButtonState] = useState(false);

  const authenticateUser = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/send-otp?email=${credentials}&isNewUser=false`;
    return fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: "",
    })
      .then((data) => data.json())
      .catch((error) => console.log(error));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonState(true);
    const token = await authenticateUser(email);
    if (token?.status === 404) {
      setAuthError(true);
      setButtonState(false);
    } else {
      // setToken(token);
      navigate("/verify-account",{
        state: { email: email}
    });
    }
  };

    const handleClose = () => {
      setAuthError(false);
    };

  return (
    <>
      <div className="Authentication ForgotPassword">
        <div className="auth__main">
          <LeftSection imgName="room3" />
          <section className="auth__right-section">
            <div></div>
            <div className="auth__welcome-msg">
              <Typography variant="h4" color="text.secondary" align="center">
                Forgot Password
              </Typography>
              <Typography variant="body2" color="text.main" align="center">
                Reset Your Password
              </Typography>
            </div>
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              className="auth__form-container"
            >
              <div className="auth__form">
                <TextField
                  label="Email Id"
                  placeholder="user@email.com"
                  className="component__textbox-big"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  variant="outlined"
                  type="email"
                  value={email}
                  error={emailError}
                  helperText={emailHelperText}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <p>
                  We will send a verification code on your registered email id.
                </p>
              </div>
              <div>
                <Button type="submit" disabled={buttonState} className="auth__btn" variant="contained">
                  Send otp
                </Button>
              </div>
            </form>
            <div className="auth__social"></div>
            <div className="auth__footer">
              <div>
                <p>Remember your password?</p>
                <Link to="/login">Login Here</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Snackbar
        open={authError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Authentication Error! Retry.
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
