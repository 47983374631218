import React from "react";
import { Link } from "react-router-dom";
import "./LeftSection.scss";

export function LeftSection(props) {
  return (
    <>
      <section
        className="LeftSection"
        style={{ backgroundImage: `url("/images/${props.imgName}.jpg")` }}
      >
        <Link to="/" className="section__home-logo">
          <img src="/logo.png" />
        </Link>
      </section>
    </>
  );
}
