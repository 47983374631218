import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import "./Facilities.scss";

export function Facilities(props) {
  const facilitiesIcon = [
    { id: 1, name: "Free-Wifi", icon: "wifi" },
    { id: 2, name: "AC", icon: "ac_unit" },
    { id: 3, name: "Non-AC", icon: "mode_cool_off" },
    { id: 4, name: "TV/LED/LCD", icon: "tv" },
    { id: 5, name: "Parking", icon: "local_parking" },
    { id: 6, name: "Kitchen/Mess", icon: "countertops" },
    { id: 7, name: "Fridge", icon: "kitchen" },
    { id: 8, name: "Gym", icon: "sports_gymnastics" },
    { id: 9, name: "Swimming Pool", icon: "pool" },
    { id: 10, name: "Laundry Service", icon: "local_laundry_service" },
    { id: 11, name: "Geyser", icon: "water_heater" },
    { id: 12, name: "Shower", icon: "shower" },
    { id: 13, name: "Press", icon: "iron" },
    { id: 14, name: "Restaurant", icon: "restaurant" },
  ];
  const [facilities, setFacilities] = useState(props.facilities);

  const getIcon = (id) => {
    const icon = facilitiesIcon.filter((val) => val.id === id);
    return `${icon[0]?.icon}`;
  };

  return (
    <div className="FacilitiesList">
      <div className="fc__facilities">
        {facilities.length > 0
          ? facilities.map((d, i) => (
              <Tooltip key={i} title={d.facilityName}>
                <span className="material-symbols-outlined">
                  {/* {getIcon(d.id)} */}
                  {d.facilityIconName}
                </span>
              </Tooltip>
            ))
          : <span className="text-secondary">Facilities not available</span>}
      </div>
    </div>
  );
}
