import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export const NotFound = () => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "2rem",
    height: "100vh",
  };
  return (
    <>
      <main style={style}>
        <img src="/logo.png" alt="logo" />
        <h1 style={{ margin: "0", color: "var(--text-secondary)" }}>
          Page Not Found
        </h1>
        <h2 style={{ color: "var(--text-secondary)" }}>
          It seems like you are lost here!
        </h2>
        <Link to="/">
          <Button variant="outlined">Go to Homepage</Button>
        </Link>
      </main>
    </>
  );
};
