import React, { useState, useEffect } from "react";
import environment from "../../../../config/environment";
import "./Facilities.scss";

export function Facilities(props) {
  const facilitiesIcon = [
    { id: 1, name: "Free-Wifi", icon: "wifi" },
    { id: 2, name: "AC", icon: "ac_unit" },
    { id: 3, name: "Non-AC", icon: "mode_cool_off" },
    { id: 4, name: "TV/LED/LCD", icon: "tv" },
    { id: 5, name: "Parking", icon: "local_parking" },
    { id: 6, name: "Kitchen/Mess", icon: "countertops" },
    { id: 7, name: "Fridge", icon: "kitchen" },
    { id: 8, name: "Gym", icon: "sports_gymnastics" },
    { id: 9, name: "Swimming Pool", icon: "pool" },
    { id: 10, name: "Laundry Service", icon: "local_laundry_service" },
    { id: 11, name: "Geyser", icon: "water_heater" },
    { id: 12, name: "Shower", icon: "shower" },
    { id: 13, name: "Press", icon: "iron" },
    { id: 14, name: "Restaurant", icon: "restaurant" },
  ];
  const [facilities, setFacilities] = useState([]);
  const [hostelId, setHostelId] = useState(props.id);
  const getFacilities = async () => {
    const apiEndpoint = `${environment.api}/api/v1/hostel-facility-mapping/${hostelId}`;

    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson ? await response.json() : null;
    if (!response.ok) {
      const error = (data && data.message) || response.status;
      return Promise.reject(error);
    }
    return data;
  };

  const getIcon = (id) => {
    const icon = facilitiesIcon.filter((val) => val.id === id);
    return `${icon[0].icon}`;
  };

  useEffect(() => {
    getFacilities()
      .then((result) => {
        setFacilities(result.response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="Facilities">
      <div className="fc__facilities">
        <header>Facilities</header>
        <div className="fc__facilities-main">
          {facilities?.map((d, i) => (
            <div key={i}>
              <span className="material-symbols-outlined">
                {/* {getIcon(d.facilities.id)} */}
                {d.facilities.facilityIconName}
              </span>
              <span>{d.facilities.facilityName}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
