import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import "./HostelDetailPage.scss";
import Navbar from "../../components/navbar/Navbar";
import Favourite from "../../components/favourite/Favourite";
import Button from "@mui/material/Button";
import Share from "../../components/share/Share";
import { HostelRules } from "./components/HostelRules/HostelRules";
import { ViewInMap } from "./components/ViewInMap/ViewInMap";
import { RoomOptions } from "../../components/hostel/RoomOptions/RoomOptions";
import { HostelName } from "../../components/hostel/HostelName/HostelName";
import environment from "../../config/environment";
import Loader from "../../components/loader/Loader";
import { Facilities } from "./components/Facilities/Facilities";
import { ImageGallery } from "./components/ImageGallery/ImageGallery";
import BackButton from "../../components/back-button/BackButton";
import { getRoomDetails } from "../../services/httpService";

export function HostelDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [hostelData, setHostelData] = useState({});
  const [nearby, setNearby] = useState("transport");
  const [restaurantList, setRestaurantList] = useState([]);
  const [healthList, setHealthList] = useState([]);
  const [gymList, setGymList] = useState([]);
  const [mallList, setMallList] = useState([]);
  const hostelId = id;
  const [loading, setLoading] = useState(true);
  const [openMapView, setOpenMapView] = useState(false);
  const [hostelRoomId, setHostelRoomId] = useState();

  const getHostelData = async () => {
    const apiEndpoint = `${environment.api}/api/v1/hostel/${hostelId}`;
    const roomDetails = await getRoomDetails({ hostelId });
    if (!roomDetails) {
    } else {
      setHostelRoomId(roomDetails?.response[0].id);
    }
    try {
      const response = await fetch(apiEndpoint, {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const handleOpenMapView = () => {
    setOpenMapView(true);
  };

  const handleCloseMapView = () => {
    setOpenMapView(false);
  };

  const handleNearbyChange = (loc) => {
    setNearby(loc);
  };

  const Nearby = () => {
    let nb;
    switch (nearby) {
      case "transport":
        nb = (
          <ul>
            <li>
              <div>
                <span>Airport</span>
                <span>
                  {getAirportDistance(
                    hostelData.latitude,
                    hostelData.longitude
                  )}{" "}
                  kms
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>Railway Station</span>
                <span>
                  {getRailwayDistance(
                    hostelData.latitude,
                    hostelData.longitude
                  )}{" "}
                  kms
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>Bus Stand</span>
                <span>
                  {getBusDistance(hostelData.latitude, hostelData.longitude)}{" "}
                  kms
                </span>
              </div>
            </li>
          </ul>
        );
        break;

      case "restaurant":
        nb = (
          <ul>
            {restaurantList.map((d, i) => {
              return (
                <li key={i}>
                  <div>
                    <span>{d?.name}</span>
                    <span>
                      {getDistance(
                        d.geometry.location.lat(),
                        d.geometry.location.lng(),
                        hostelData.latitude,
                        hostelData.longitude
                      )}{" "}
                      kms
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        );
        break;

      case "gym":
        nb = (
          <ul>
            {gymList.map((d, i) => {
              return (
                <li key={i}>
                  <div>
                    <span>{d?.name}</span>
                    <span>
                      {getDistance(
                        d.geometry.location.lat(),
                        d.geometry.location.lng(),
                        hostelData.latitude,
                        hostelData.longitude
                      )}{" "}
                      kms
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        );
        break;

      case "health":
        nb = (
          <ul>
            {healthList.map((d, i) => {
              return (
                <li key={i}>
                  <div>
                    <span>{d?.name}</span>
                    <span>
                      {getDistance(
                        d.geometry.location.lat(),
                        d.geometry.location.lng(),
                        hostelData.latitude,
                        hostelData.longitude
                      )}{" "}
                      kms
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        );
        break;

      case "mall":
        nb = (
          <ul>
            {mallList.map((d, i) => {
              return (
                <li key={i}>
                  <div>
                    <span>{d?.name}</span>
                    <span>
                      {getDistance(
                        d.geometry.location.lat(),
                        d.geometry.location.lng(),
                        hostelData.latitude,
                        hostelData.longitude
                      )}{" "}
                      kms
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        );
        break;

      default:
        break;
    }
    return nb;
  };

  const getAirportDistance = (currentLat, currentLng) => {
    const lat = 18.5791611;
    const lng = 73.9065642;
    return getDistance(lat, lng, currentLat, currentLng);
  };

  const getRailwayDistance = (currentLat, currentLng) => {
    const lat = 18.528918;
    const lng = 73.8699352;
    return getDistance(lat, lng, currentLat, currentLng);
  };

  const getBusDistance = (currentLat, currentLng) => {
    const lat = 18.4989315;
    const lng = 73.8473975;
    return getDistance(lat, lng, currentLat, currentLng);
  };

  const getDistance = (lat, lng, currentLat, currentLng) => {
    const earthRadius = 6371;
    let dist = 0;

    if (currentLat && currentLng) {
      const dLat = (currentLat - lat) * (Math.PI / 180);
      const dLng = (currentLng - lng) * (Math.PI / 180);
      const sinDLat = Math.sin(dLat / 2);
      const sinDLng = Math.sin(dLng / 2);

      const a =
        Math.pow(sinDLat, 2) +
        Math.pow(sinDLng, 2) *
          Math.cos(lat * (Math.PI / 180)) *
          Math.cos(currentLat * (Math.PI / 180));

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      dist = earthRadius * c;
    }
    return Math.round(dist * 10) / 10;
  };

  const handleBookNow = async () => {
    navigate(`/booking-detail`, {
      state: { hostelId: hostelId, hostelRoomId: hostelRoomId },
    });
  };

  const handleSendQuery = () => {
    navigate(`/make-enquiry`);
  };

  useEffect(() => {
    getHostelData()
      .then((result) => {
        setHostelData(result.response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      let map, service;
      const initMap = async () => {
        const position = {
          lat: hostelData.latitude,
          lng: hostelData.longitude,
        };
        const { Map } = await window.google.maps.importLibrary("maps");
        const { Marker } = await window.google.maps.importLibrary("marker");
        const radius = "3000";

        map = await new Map(document.getElementById("viewInSmallMap"), {
          center: position,
          zoom: 12,
          mapTypeControl: false,
          disableDefaultUI: true,
        });

        const restaurantRequest = {
          location: position,
          radius: radius,
          type: ["restaurant"],
        };

        const gymRequest = {
          location: position,
          radius: radius,
          type: ["gym"],
        };

        const healthRequest = {
          location: position,
          radius: radius,
          type: ["hospital"],
        };

        const shoppingMallRequest = {
          location: position,
          radius: radius,
          type: ["shopping_mall"],
        };

        service = new window.google.maps.places.PlacesService(map);
        service.nearbySearch(restaurantRequest, setRestaurant);
        service.nearbySearch(gymRequest, setGym);
        service.nearbySearch(healthRequest, setHealth);
        service.nearbySearch(shoppingMallRequest, setShoppingMall);

        const marker = new Marker({
          map: map,
          position: position,
          icon: "/images/marker.png",
        });

        function setRestaurant(results, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const data = results.filter((d) => {
              return d?.business_status === "OPERATIONAL";
            });
            setRestaurantList(data.slice(0, 5));
          }
        }

        function setGym(results, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const data = results.filter((d) => {
              return d?.business_status === "OPERATIONAL";
            });
            setGymList(data.slice(0, 5));
          }
        }

        function setHealth(results, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const data = results.filter((d) => {
              return d?.business_status === "OPERATIONAL";
            });
            setHealthList(data.slice(0, 5));
          }
        }

        function setShoppingMall(results, status) {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const data = results.filter((d) => {
              return d?.business_status === "OPERATIONAL";
            });
            setMallList(data.slice(0, 5));
          }
        }
      };

      initMap();
    }
  }, [loading]);

  return (
    <>
      <div className="HostelDetailPage">
        <Header />
        <Navbar />
        {loading ? (
          <Loader />
        ) : (
          <Container sx={{ padding: { xs: 0, sm: "0 24px" } }}>
            <div className="hd__top-toolbar">
              <BackButton />
            </div>
            <main className="hd__main-container">
              <section className="hd__main">
                <section className="hd__left">
                  <ImageGallery id={hostelId} />
                  <div className="hd__view-on-map">
                    <Button variant="contained" onClick={handleOpenMapView}>
                      View on map
                    </Button>
                    <ViewInMap
                      hostelData={hostelData}
                      open={openMapView}
                      onClose={handleCloseMapView}
                    />
                  </div>
                  <div className="hd__rules">
                    <Facilities id={hostelId} />
                    <HostelRules id={hostelId} />
                  </div>
                </section>
                <section className="hd__right">
                  <div className="hd__fav-right">
                    <Favourite />
                    <Share 
                    hostelName={hostelData.hostelName}
                    hostelAddress={hostelData.hostelAddress}
                    />
                  </div>
                  <HostelName
                    name={hostelData.hostelName}
                    rating="4.5"
                    location={hostelData.hostelAddress}
                  />
                  <RoomOptions id={hostelId} />

                  <div className="hd__description">
                    <header>Description</header>
                    <p>
                      {hostelData?.hostelDescription === null
                        ? "Description not available"
                        : hostelData?.hostelDescription}
                    </p>
                  </div>

                  <div className="hd__location-map">
                    <div className="hd__location-map-header">
                      <header>Location</header>
                      <span onClick={handleOpenMapView}>View on map</span>
                    </div>
                    <div className="hd__location-map-container">
                      <div
                        id="viewInSmallMap"
                        style={{ width: "100%", height: "100%" }}
                      ></div>
                    </div>
                  </div>

                  <div className="hd__nearby">
                    <header>Nearby</header>
                    <div className="hd__nearby-main">
                      <div className="hd__nearby-left">
                        <div
                          className={nearby === "transport" ? "active" : ""}
                          onClick={() => handleNearbyChange("transport")}
                        >
                          Transport
                        </div>
                        <div
                          className={nearby === "restaurant" ? "active" : ""}
                          onClick={() => handleNearbyChange("restaurant")}
                        >
                          Restaurants
                        </div>
                        <div
                          className={nearby === "health" ? "active" : ""}
                          onClick={() => handleNearbyChange("health")}
                        >
                          Health Care
                        </div>
                        <div
                          className={nearby === "gym" ? "active" : ""}
                          onClick={() => handleNearbyChange("gym")}
                        >
                          Gym
                        </div>
                        <div
                          className={nearby === "mall" ? "active" : ""}
                          onClick={() => handleNearbyChange("mall")}
                        >
                          Shopping Malls
                        </div>
                      </div>
                      <div className="hd__nearby-right">
                        <Nearby />
                      </div>
                    </div>
                  </div>

                  <div className="hd__rules-right">
                    <Facilities id={hostelId} />
                    <HostelRules id={hostelId} />
                  </div>

                  <div className="hd__btn-container">
                    <div className="hd__send-query">
                      <Button
                        className="hd__send-query-btn"
                        variant="outlined"
                        onClick={handleSendQuery}
                      >
                        Send Query
                      </Button>
                    </div>

                    <div className="hd__book-now">
                      <Button
                        className="hd__book-now-btn"
                        variant="contained"
                        onClick={handleBookNow}
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                </section>
                <div className="hd__fav">
                  <Favourite bgColor="white" />
                  <Share bgColor="white"/>
                </div>
              </section>
              <aside></aside>
            </main>
          </Container>
        )}

        <Footer />
      </div>
    </>
  );
}
