import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, CircularProgress, FormLabel } from "@mui/material";
import { getEmiSummary } from "../../../../services/httpService";
import "./PaymentSummary.scss";
import useToken from "../../../../services/useToken";

export function PaymentSummary(props) {
  // console.log("props", props);
  const [data, setData] = useState();
  const { token } = useToken();
  const [emi, setEmi] = useState([]);
  const [openPaymentStructure, setPaymentStructure] = useState(false);

  const handleOpenPaymentStructure = async () => {
    //console.log("props", props);
    const payment = await getEmiSummary({
      hostelRoomId: props?.hostelId,
      stayDuration: props?.stayDuration,
      payDuration: props?.payDuration,
      token: token ? token : "",
      startDate: props?.moveInDate,
    });
    if (!payment) {
    } else {
      setEmi(payment.response?.paymentEmiDetails);
      setPaymentStructure(true);
    }
  };

  const handleClosePaymentStructure = () => {
    setPaymentStructure(false);
  };

  const getTax = (tax) => {
    let t = 0;
    tax?.forEach((d) => (t += parseInt(d.taxAmount)));
    return t;
  };

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  return (
    <div className="PaymentSummary">
      <div className="bd__payment-summary">
        <h3>Payment Summary</h3>
        <div className="bd__payment-summary-calc">
          {/* <div>
                      <div>Room Rent [per month]</div>
                      <div>&#8377; {data?.monthlyRent}</div>
                    </div> */}
          <div>
            <div>Total Rent</div>
            <div>&#8377; {data?.totalActualRent}</div>
          </div>
          {data?.hostelDiscount !== null ? (
            <>
              <div>
                <div>{data?.hostelDiscount.discountName}</div>
                <div>- &#8377; {data?.hostelDiscount.flatAmount}</div>
              </div>
            </>
          ) : (
            ""
          )}
          {data?.couponResponseModel !== null ? (
            <>
              <div>
                <div>{data?.couponResponseModel.discountType}</div>
                <div>- &#8377; {data?.couponResponseModel.discountAmount}</div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* {data?.totalTaxDetails.map((d, i) => (
            <div key={i}>
              <div>
                {d.taxName}
                {" @ "}
                {d.percentage}
              </div>
              <div>&#8377; {d.taxAmount}</div>
            </div>
          ))} */}

          {data?.hostelDiscount !== null ||
          data?.couponResponseModel !== null ? (
            <div className="bd__payment-summary-st">
              <div>Sub Total</div>
              <div>&#8377; {data?.grandTotal - data?.securityDeposit}</div>
            </div>
          ) : (
            ""
          )}

          {/* <div className="bd__payment-summary-gt">
            <div>Sub Total</div>
            <div>&#8377; {data?.grandTotal - data?.securityDeposit}</div>
          </div> */}
          <div>
            <div>Security Deposit</div>
            <div>&#8377; {data?.securityDeposit}</div>
          </div>
          <div className="bd__payment-summary-gt">
            <div>Grand Total</div>
            <div>&#8377; {data?.grandTotal}</div>
          </div>
        </div>
        <p
          onClick={handleOpenPaymentStructure}
          className="bd__payment-summary-ps"
        >
          View Payment Structure
        </p>
        <Dialog
          maxWidth="md"
          open={openPaymentStructure}
          onClose={handleClosePaymentStructure}
        >
          <DialogTitle>Payment Structure</DialogTitle>
          <DialogContent>
            <p>Recurring payment installments you need to pay:</p>
            <TableContainer
              component={Paper}
              className="bd__payment-summary-pst"
            >
              <Table sx={{ minWidth: 540 }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="120">Due Date</TableCell>
                    <TableCell width="125" align="right">
                      Rent
                    </TableCell>
                    <TableCell width="150" align="right">
                      Security Deposit
                    </TableCell>
                    {/* <TableCell width="120" align="right">
                      Tax
                    </TableCell> */}
                    <TableCell width="120" align="right">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emi.map((row) => (
                    <TableRow
                      key={row.paymentDueDate}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.paymentDueDate.slice(0, 10)}
                      </TableCell>
                      <TableCell align="right">&#8377; {row.rent}</TableCell>
                      <TableCell align="right">
                        &#8377; {row.securityDeposit}
                      </TableCell>
                      {/* <TableCell align="right">
                        &#8377; {getTax(row.tax)}
                      </TableCell> */}
                      <TableCell align="right">
                        <strong>
                          &#8377;
                          {row.grandTotal}
                        </strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePaymentStructure} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
