import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import "./UpdateProfile.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogContentText
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import {
  getMobileOtp,
  getUserDetail,
  updateUserDetail,
  updatePersonalDetails,
  updateGuardianDetails,
  setPassword,
  resetPassword,
  verifyMobileOtp,
  updateProfilePic,
} from "../../services/httpService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useToken from "../../services/useToken";
import environment from "../../config/environment";
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";

import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

export function UpdateProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { token, userId } = useToken();
  const [userData, setUserData] = useState();
  const [userDetails, setUesrDetails] = useState();

  const [passwordOne, setPasswordOne] = useState("");
  const [error, setError] = React.useState(false);
  const [passwordTwo, setPasswordTwo] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openEditGuardianDetails, setOpenEditGuardianDetails] = useState(false);
  const [openVerifyMobileNumber, setVerifyMobileNumber] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [guardianName, setguardianName] = useState("");
  const [guardianRelation, setGuardianRelation] = useState("");
  const [guardianMobile, setGuardianMobile] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [profilePicUrl, setprofilePicUrl] = useState("");
  const [updateProfileError, setUpdateProfileError] = useState(false);
  const [updateGuardianDetailsError, setUpdateGuardianDetailsError] = useState(false);
  const [updatePasswordError, setUpdatePasswordError] = useState(false);
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
  const [updateGuardianDetailsSuccess, setUpdateGuardianDetailsSuccess] = useState(false);
  const [verifyMobileNumberSuccess, setVerifyMobileNumberSuccess] =
    useState(false);
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isGetOtpDisabled, setGetOtpDisabled] = useState(false);
  const [isMobileNoCorrect, setMobileNoCorrect] = useState(true);
  const [mobileotp1, setMobileOtp1] = useState("");
  const [mobileotp2, setMobileOtp2] = useState("");
  const [mobileotp3, setMobileOtp3] = useState("");
  const [mobileotp4, setMobileOtp4] = useState("");
  const [mobileotp5, setMobileOtp5] = useState("");
  const [mobileotp6, setMobileOtp6] = useState("");
  const [mobileotp, setMobileOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setUpdateProfileError(false);
    setUpdateProfileSuccess(false);
    setVerifyMobileNumberSuccess(false);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleOpenEditProfile = () => {
    setOpenEditProfile(true);
  };

  const handleOpenEditGuardianDetails = () => {
    setOpenEditGuardianDetails(true);
  };

  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
  };

  const handleCloseEditGuardianDetails = () => {
    setOpenEditGuardianDetails(false);
  };

  const handleCloseVerifyMobile = () => {
    setVerifyMobileNumber(false);
  };

  const handleUpdateProfile = async () => {
    const up = await updatePersonalDetails({
      token,
      body: {
        userId,
        fullName,
        mobileNo: mobile,
        dateOfBirth: dob,
        gender: gender,
        address: permanentAddress,
        postalCode: postalCode,
      },
    });
    if (!up) {
      setUpdateProfileError(true);
    } else {
      setUpdateProfileSuccess(true);
      setOpenEditProfile(false);
      handleGetUserDetail();
    }
  };

  const handleUpdateGuardianDetails = async (event) => {
    const gup = await updateGuardianDetails({
      token,
      body: {
        userId,
        guardianName: guardianName,
        guardianRelation: guardianRelation,
        guardianMobileNo: guardianMobile,
        guardianEmail: guardianEmail,
      },
    });
    if (!gup) {
      updateGuardianDetailsError(true);
    } else {
      updateGuardianDetailsSuccess(true);
      openEditGuardianDetails(false);
      handleGetUserDetail();
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    const upr = await setPassword({
      token,
      body: {
        username: email,
        password: passwordOne,
      },
    });
    if (!upr) {
      setUpdatePasswordError(true);
    } else {
      setUpdatePasswordSuccess(true);
      setOpenChangePassword(false);
      navigate("/logout");
    }
  };

  const handleVerifyMobileOtp = async (event) => {
    event.preventDefault();
    const verifyMobileResponse = await verifyMobileOtp({
      userId,
      mobileotp,
      token,
    });

    if (!verifyMobileResponse) {
      setErrorMsg("Incorrect OTP");
      setOpenSnackBar(true);
    } else {
      setVerifyMobileNumber(false);
      setVerifyMobileNumberSuccess(true);
      handleGetUserDetail();
    }
  };

  const handlePicSelect = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    var formdata = new FormData();
    if (selectedFile) {
      try {
        formdata.append("file", selectedFile);
        const upp = await updateProfilePic({
          token,
          userId,
          body: formdata
        });
        if(upp){
          //refresh the page
          handleGetUserDetail();
        }
      } catch (error) {
      }
    }
  }


  const handlePasswordTwo = async (val) => {
    if (val !== passwordOne) {
      setPasswordTwo(val);
      setPasswordError("Password does not match");
    } else {
      setPasswordTwo(val);
      setPasswordError("");
    }
  };

  useEffect(() => {
    setLoading(false);
    handleGetUserDetail();
  }, []);

  const handleGetUserDetail = async () => {
    const user = await getUserDetail({
      userId,
      token,
    });
    if (!user) {
    } else {
      setUserData(user?.response);
      setFullName(user?.response?.fullName);
      setMobile(user?.response?.mobileNo);
      setEmail(user?.response?.username);
      setDob(user?.response?.userDetails?.dateOfBirth);
      setGender(user?.response?.userDetails?.gender)
      setUesrDetails(user?.response?.userDetails);
      setPermanentAddress(user?.response?.userDetails?.address);
      setPostalCode(user?.response?.userDetails?.postalCode);
      setguardianName(user?.response?.userDetails?.guardianName);
      setGuardianRelation(user?.response?.userDetails?.guardianRelation);
      setGuardianMobile(user?.response?.userDetails?.guardianMobileNo);
      setGuardianEmail(user?.response?.userDetails?.guardianEmail);
      setprofilePicUrl(user?.response?.imageAttachment?.imagePath);
    }
  };

  const handleGetMobileOtp = async (event) => {
    const mobile = userData?.mobileNo;
    event.preventDefault();
    const response = await getMobileOtp({
      token,
      mobile,
    });

    if (!response) {
      setOpenSnackBar(true);
      setGetOtpDisabled(false);
    } else {
      setVerifyMobileNumber(true);
    }
  };

  const handleMobileOtp1 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp1(val);
    document.querySelector(".mobileotp2 input").focus();
  };

  const handleMobileOtp2 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp2(val);
    document.querySelector(".mobileotp3 input").focus();
  };

  const handleMobileOtp3 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp3(val);
    document.querySelector(".mobileotp4 input").focus();
  };

  const handleMobileOtp4 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp4(val);
    document.querySelector(".mobileotp5 input").focus();
  };

  const handleMobileOtp5 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp5(val);
    document.querySelector(".mobileotp6 input").focus();
  };

  const handleMobileOtp6 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp6(val);
    setMobileOtp(
      `${mobileotp1}${mobileotp2}${mobileotp3}${mobileotp4}${mobileotp5}${val}`
    );
  };
  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleGuardianRelationshipChange = (event) => {
    setGuardianRelation(event.target.value);
  };
  const handleDobChange = (newValue) => {
    setDob(dayjs(newValue).format('DD/MM/YYYY'));
  };
  // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.", dob);
  // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!GENDER", gender)
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
  const errorMessage = 'Invalid Password Pattern';
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;

    // Check if the new password matches the regex pattern
    const isValid = passwordRegex.test(newPassword);

    // Update the password state and error state
    setPasswordOne(newPassword);
    setError(!isValid);
  };
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  return (
    <div className="UpdateProfile">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <div className="mainContainer">
            <div className="topRow">
              <h3 className="topRow_title">Personal Details</h3>
              <div className="topRow_profilePic">
                {(() => {
                  if (profilePicUrl != undefined || profilePicUrl != null) {
                    return (
                      <img src={profilePicUrl} alt="Avatar" style={{borderRadius: "20px"}} />
                    )
                  }
                  else {
                    return (
                      <span className="mat-icon material-symbols-outlined">
                        person
                      </span>
                    )
                  }
                })()}
              </div>
              <div className="topRow_btn">
                <Button onClick={handleOpenEditProfile} variant="outlined">
                  Edit
                </Button>
              </div>
            </div>
            <div sx={{ justifyContent: "center" }}>
              <Button component="label" variant="raised" sx={{ textDecoration: "underline", color: "rgba(255, 102, 0, 1)", height: "15px", width: "fit-content", fontWeight: "400", fontSize: "13px", marginLeft: "47.5%" }}>
                Change photo
                <VisuallyHiddenInput type="file" accept="image/*" onChange={handlePicSelect} />
              </Button>
            </div>
            <div className="infoRow">
              <div className="infoRow_name"> Full Name</div>
              <div className="infoRow_value">{userData?.fullName}</div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Email Id</div>
              <div className="infoRow_value">{userData?.username}</div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Mobile Number</div>
              <div className="infoRow_value">{userData?.mobileNo}</div>
              <span className="mainSpan">
                {userData?.mobileNo != null ? "" : <div className="topRow_btn">
                  <Button onClick={handleOpenEditProfile} variant="outlined">
                    Update
                  </Button>
                </div>}{" "}
                {userData?.isPhoneNumberVerified
                  ? <><span className="verifiedSpan mat-icon material-symbols-outlined">verified_user</span><span> Verified</span></>
                  : <><span className="nonVerifiedSpan mat-icon material-symbols-outlined">gpp_bad</span><Button onClick={handleGetMobileOtp}>
                    Not Verified
                  </Button></>}
              </span>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Gender</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.gender
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Date Of Birth</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.dateOfBirth
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Permanent Address</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.address + ", " + userDetails.postalCode
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mainContainer"><br />
            <div className="topRow">
              <h3 className="topRow_title">Guardian Details</h3>
              <div className="topRow_btn">
                <Button onClick={handleOpenEditGuardianDetails} variant="outlined">
                  Edit
                </Button>
              </div>
            </div><br />
            <div className="infoRow">
              <div className="infoRow_name">Guardian Full Name</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.guardianName
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Relation with Student</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.guardianRelation
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Mobile Number</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.guardianMobileNo
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
            <div className="infoRow">
              <div className="infoRow_name"> Email Id</div>
              <div className="infoRow_value">
                {(() => {
                  if (userDetails != undefined || userDetails != null) {
                    return (
                      userDetails.guardianEmail
                    )
                  }
                  else {
                    return (
                      `N/A`
                    )
                  }
                })()}
              </div>
              <div className="infoRow_btn"></div>
            </div>
            <div className="line"></div>
          </div>
          <div className="mainContainer"><br />
            <div className="topRow">
              <h3 className="topRow_title">
                Security Settings<br />
                <span className="subText">For security reasons you should change your password every 90 days.</span>
              </h3>
              <div className="topRow_btn">
                <Button onClick={handleOpenChangePassword} variant="outlined">
                  Change Password
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="mainContainer" ><br />
            <div className="topRow">
              <h3 className="topRow_title">
                Notification Settings<br />
                <span className="subText">Turn app notification on/off</span>
              </h3>
              <div className="topRow_btn">
                <Switch defaultChecked />
              </div>
            </div>
          </div> */}
        </Container>
      )}

      <Footer />
      <Dialog
        maxWidth="xl"
        open={openEditProfile}
        onClose={handleCloseEditProfile}
      >
        <form onSubmit={handleUpdateProfile}>
          <DialogTitle>
            Update Personal Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change the information in the form below to update your personal details.
            </DialogContentText>
            <div className="up__change-popup">
              <TextField
                label="Full Name"
                placeholder="Enter your full name"
                onChange={(e) => setFullName(e.target.value)}
                required
                variant="outlined"
                type="text"
                value={fullName}
                className="component__textbox-big"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email Id"
                className="component__textbox-big"
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={true}
                variant="outlined"
                type="text"
                value={email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Mobile Number"
                placeholder="9876543210"
                className="component__textbox-big"
                onChange={(e) => setMobile(e.target.value)}
                required
                variant="outlined"
                type="text"
                value={mobile}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 10,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

            </div>
            <div className="up__change-popup">
              <FormControl required sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  defaultValue={gender}
                  label="Gender"
                  placeholder="Gender"
                  onChange={handleGenderChange}
                  sx={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    '& .MuiSvgIcon-root': {
                      color: 'rgba(0, 0, 0, 0.7)'
                    }
                  }}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DatePicker
                  label="Date Picker"
                  disableFuture
                  format="DD/MM/YYYY"
                  required
                  defaultValue={dayjs(dob, 'DD/MM/YYYY')}
                  onChange={handleDobChange}
                />

              </LocalizationProvider>
            </div>
            <div className="line"></div>
            <div className="up__change-popup">
              <TextField
                label="Permanent Address"
                placeholder="Enter your permanent address"
                onChange={(e) => setPermanentAddress(e.target.value)}
                required
                variant="outlined"
                type="text"
                value={permanentAddress}
                className="component__textbox-bigX"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Postal Code"
                placeholder="Enter your postal code"
                onChange={(e) => setPostalCode(e.target.value)}
                required
                variant="outlined"
                value={postalCode}
                className="component__textbox-big"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 6,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonPinCircleOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditProfile}>Close</Button>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>




      <Dialog
        maxWidth="xl"
        open={openEditGuardianDetails}
        onClose={handleCloseEditGuardianDetails}
      >
        <form onSubmit={handleUpdateGuardianDetails}>
          <DialogTitle>
            Update Guardian Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change the information in the form below to update your guardian details.
            </DialogContentText>
            <div className="up__change-popup">
              <TextField
                label="Guardian Full Name"
                placeholder="Enter Guardian's full name"
                onChange={(e) => setguardianName(e.target.value)}
                required
                variant="outlined"
                type="text"
                value={guardianName}
                className="component__textbox-big"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email Id"
                className="component__textbox-big"
                onChange={(e) => setGuardianEmail(e.target.value)}
                required
                variant="outlined"
                type="email"
                value={guardianEmail}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Guardian Mobile Number"
                placeholder="9876543210"
                className="component__textbox-big"
                onChange={(e) => setGuardianMobile(e.target.value)}
                required
                variant="outlined"
                type="text"
                value={guardianMobile}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 10,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

            </div>
            <div className="up__change-popup">
              <FormControl required sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Relationship</InputLabel>
                <Select
                  defaultValue={guardianRelation}
                  label="relationship"
                  placeholder="relationship"
                  onChange={handleGuardianRelationshipChange}
                  sx={{
                    color: 'rgba(0, 0, 0, 0.7)',
                    '& .MuiSvgIcon-root': {
                      color: 'rgba(0, 0, 0, 0.7)'
                    }
                  }}
                >
                  <MenuItem value="Father">Father</MenuItem>
                  <MenuItem value="Mother">Mother</MenuItem>
                  <MenuItem value="Grand Father">Grand Father</MenuItem>
                  <MenuItem value="Brother">Brother</MenuItem>
                  <MenuItem value="Sister">Sister</MenuItem>
                  <MenuItem value="Grand Mother">Grand Mother</MenuItem>
                  <MenuItem value="Uncle">Uncle</MenuItem>
                  <MenuItem value="Aunt">Aunt</MenuItem>
                  <MenuItem value="Family Friend">Family Friend</MenuItem>
                  <MenuItem value="Others ">Others</MenuItem>
                </Select>
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditGuardianDetails}>Close</Button>
            <Button variant="outlined" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>






      <Dialog
        maxWidth="lg"
        open={openChangePassword}
        onClose={handleCloseChangePassword}
      >
        <form onSubmit={handleChangePassword}>
          <DialogTitle>Update Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Create a new password below.
            </DialogContentText>
            <div className="up__change-popup">
              <Grid item xs={12} md={6}>
                <Typography sx={{ mt: 0, mb: 0, color: "rgba(0, 0, 0, 0.64)", fontSize: "14px", fontWeight: 500 }} component="div">
                  Password rules:
                </Typography>
                <List sx={{ color: "rgba(0, 0, 0, 0.64)", fontSize: "14px", fontWeight: 400 }}>
                  <ListItem>
                    1. Password should be minimum 8 characters.
                  </ListItem>
                  <ListItem>
                    2. Password should contain A-Z, a-z, 0-9
                  </ListItem>
                  <ListItem>
                    3. Only one special characters is allowed.
                  </ListItem>
                  <ListItem>
                    4. Last 3 password should not be used.
                  </ListItem>
                </List>
              </Grid>
              <div className="verticalLine"></div>
              <div>
                <TextField
                  label="Enter Password"
                  placeholder="**********"
                  onChange={handlePasswordChange}
                  error={error}
                  required
                  variant="outlined"
                  type="password"
                  value={passwordOne}
                  className="component__textbox-big"
                  helperText={error ? errorMessage : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    pattern: passwordRegex.source, // Set the regex pattern for the input element
                    title: errorMessage, // Set the error message for accessibility
                  }}
                /><br />

                <TextField
                  sx={{ marginTop: "60px" }}
                  label="Re-enter Password"
                  placeholder="**********"
                  onChange={(e) => handlePasswordTwo(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={passwordTwo}
                  error={passwordError}
                  className="component__textbox-big"
                  helperText={passwordError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseChangePassword}>Close</Button>
            <Button variant="outlined" type="submit" disabled={error || passwordOne === '' || passwordError}>
              Save
            </Button>
          </DialogActions>
        </form>
        <span className="pText__danger">**Current session will be expired on successful password change.</span>
      </Dialog>
      <Dialog
        maxWidth="lg"
        open={openVerifyMobileNumber}
      // onClose={handleCloseVerifyMobile}
      >
        <form onSubmit={handleVerifyMobileOtp}>
          <DialogTitle>Verify Mobile Number</DialogTitle>
          <DialogContent>
            <div className="up__change-popup">
              <h4>Enter OTP sent on your mobile number</h4>
              <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  onClose={handleSnackBarClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMsg}
                </Alert>
              </Snackbar>
              <div className="auth__step2-otp">
                <TextField
                  className="component__textbox-otp mobileotp1"
                  onChange={(e) => handleMobileOtp1(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  autoFocus={true}
                  value={mobileotp1}
                />
                <TextField
                  className="component__textbox-otp mobileotp2"
                  onChange={(e) => handleMobileOtp2(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp2}
                />
                <TextField
                  className="component__textbox-otp mobileotp3"
                  onChange={(e) => handleMobileOtp3(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp3}
                />
                <TextField
                  className="component__textbox-otp mobileotp4"
                  onChange={(e) => handleMobileOtp4(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp4}
                />

                <TextField
                  className="component__textbox-otp mobileotp5"
                  onChange={(e) => handleMobileOtp5(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp5}
                />

                <TextField
                  className="component__textbox-otp mobileotp6"
                  onChange={(e) => handleMobileOtp6(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp6}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVerifyMobile}>Close</Button>
            <Button variant="outlined" type="submit">
              Verify
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={updateProfileError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Server Error! Retry.
        </Alert>
      </Snackbar>

      <Snackbar
        open={updateProfileSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Profile Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={verifyMobileNumberSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Mobile Number Verified Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={updateProfileError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Server Error! Retry.
        </Alert>
      </Snackbar>

      <Snackbar
        open={updateProfileError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Server Error! Retry.
        </Alert>
      </Snackbar>
    </div>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
