import React from "react";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/system";
import "./Footer.scss";

export default function Footer(props) {
  const footerStyle = {};
  return (
    <footer className="component__footer">
      <Container>
        <div className="component__footer-main">
          
          <p>Copyright {new Date().getFullYear()} | All Rights Reserved</p>

          <ul>
            <li>
              <Link to="/faqs">FAQs</Link>
              {/* <Link to="/about-us">About Us</Link> */}
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-of-use">Terms of use</Link>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul>
            <li
              style={{height: "30px",paddingTop: "5px"}}
            >
              <Link
                to="https://www.facebook.com/myhostelroom?mibextid=LQQJ4d"
                target="_blank"
              >
                <span class="facebook-links">
                  <img alt="Facebook" src="/images/facebook.svg" />
                </span>
              </Link>
              <Link
                to="https://www.instagram.com/myhostelroom_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
              >
                <span class="insta-links">
                  <img alt="instagtramicon" src="/images/instagram.svg" />
                </span>
              </Link>
              <Link
                to="https://www.linkedin.com/company/myhostelroom/"
                target="_blank"
              >
                <span class="linkedin-links">
                  <img alt="linkedinicon" src="/images/linked-in.svg" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
}
