import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Footer from "../../components/footer/Footer";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "./Homepage.scss";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";

export function Homepage() {
  const [errorText, setErrorText] = useState("");
  const [currentLat, setCurrentLat] = useState("");
  const [currentLng, setCurrentLng] = useState("");
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let autocomplete;
    let searchInputBox;
    const initAutocomplete = async () => {
      const { Autocomplete } = await window.google.maps.importLibrary("places");
      searchInputBox = document.querySelector("#searchTextBox");
      autocomplete = new Autocomplete(searchInputBox, {
        componentRestrictions: { country: ["in"] },
        fields: ["address_components", "geometry"],
        location: { lat: 18.5424, lng: 73.8567 },
        radius: 1000,
        language: "en",
        offset: 3,
      });
      autocomplete.addListener("place_changed", fillInAddress);
    };

    function fillInAddress() {
      const place = autocomplete.getPlace();
      const location = place.geometry.location;
      const lat = location.lat();
      const lng = location.lng();
      setCurrentLat(lat);
      setCurrentLng(lng);
    }
    initAutocomplete();
  }, []);

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      let searchValue = document.querySelector("#searchTextBox").value,
        place;
      place = searchValue.replace(/\s/g, "_");
      navigate(`/hostel-list/hostel_in_${place}`, {
        state: { lat: currentLat, lng: currentLng },
      });
    }
  };

  const handleClickSearch = () => {
    let searchValue = document.querySelector("#searchTextBox").value,
      place;
    if (searchValue === "" || searchValue === undefined) {
      setErrorText("Please enter a seach criteria.");
    } else {
      place = searchValue.replace(/\s/g, "_");
      navigate(`/hostel-list/hostel_in_${place}`, {
        state: { lat: currentLat, lng: currentLng },
      });
    }
  };

  const handleCloseLocation = () => {
    setOpenLocationDialog(false);
  };

  const handleOpenLocation = () => {
    setOpenLocationDialog(true);
  };

  return (
    <>
      <div className="Homepage">
        <Header />
        <Navbar />
        <main className="section__main">
          <Container>
            <section className="section__home-main">
              <img src="/images/homepage-bg.png" alt="" />
              <Box component="form" className="component__search-form">
                <FormControl
                  className="component__textbox-big"
                  variant="outlined"
                >
                  <InputLabel htmlFor="searchTextBox">
                    Search your hostel
                  </InputLabel>
                  <OutlinedInput
                    placeholder="Enter city, locality or hostel name"
                    defaultValue={"Pune, Maharashtra"}
                    onKeyDown={onEnterPress}
                    id="searchTextBox"
                    type="text"
                    label="Search your hostel"
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClickSearch}
                          variant="contained"
                          color="primary"
                        >
                          Search
                        </Button>
                        {/*<IconButton onClick={handleClickSearch} edge="end">
                           <SearchOutlinedIcon /> 
                        </IconButton>*/}
                      </InputAdornment>
                    }
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {errorText}
                  </FormHelperText>
                </FormControl>
                <div className="section__home-ls">
                  <span className="mat-icon material-symbols-outlined">
                    near_me
                  </span>
                  <span onClick={handleOpenLocation}>Pune, Maharashtra</span>
                </div>
                <Dialog open={openLocationDialog} onClose={handleCloseLocation}>
                  <DialogTitle id="alert-dialog-title">
                    {"Select a City"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      className="section__home-ls-main"
                      id="alert-dialog-description"
                    >
                      <img src="/images/pune-icon.png" alt="pune-icon" />
                      <span>
                        Currently our hostels are in and around{" "}
                        <strong>Pune, Maharashtra</strong> only. We are coming
                        to other cities very soon.
                      </span>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseLocation} autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>

              <div
                className="section__home-hero-text"
                style={{ textAlign: "center" }}
              >
                <Typography variant="h5" color="text.main">
                  Let us find you a
                </Typography>
                <Typography variant="h3" color="text.primary">
                  Second Home
                </Typography>
              </div>

              {/* <Link to="/" className="section__home-logo">
                <img src="/logo.png" alt="Logo" />
              </Link> */}
              {/* <div className="section__home-main-user">Hello Nitish</div> */}
            </section>
          </Container>
          <Footer />
        </main>
      </div>
    </>
  );
}
