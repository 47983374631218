import React, { useState, useEffect } from "react";
import environment from "../../../../config/environment";
import "./RoomOptions.scss";
import { Tooltip } from "@mui/material";

export function RoomOptions(props) {
  const [roomOptions, setRoomOptions] = useState([]);
  const hostelId = props.id;
  const getRoomOptions = async () => {
    const apiEndpoint = `${environment.api}/api/v1/hostel-with-room-details/${hostelId}`;
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  const getRoomTypeIcon = (rt) => {
    let rtIcon;
    switch (rt) {
      case "Single Bed":
        rtIcon = "person";
        break;

      case "Double Bed":
        rtIcon = "group";
        break;

      case "Triple Bed":
        rtIcon = "diversity_3";
        break;

      default:
        rtIcon = "single_bed";
        break;
    }
    return rtIcon;
  };

  const handleRoomChange = (event) => {
    props.selectRoom(event.target.value);
    props.selectRoomName(event.target.attributes.dataname.value);
  };

  useEffect(() => {
    if (hostelId) {
      getRoomOptions().then((result) => {
        setRoomOptions(result.response);
        props.selectRoom(result.response[0].id);
        props.selectRoomName(result?.response[0].roomType.roomTypeName);
      });
    }
  }, []);

  return (
    <div className="BookingDetailRoomOptions">
      <div className="ro__container">
        {roomOptions.map((d) => {
          return (
            <div key={d.id} className="ro__container-main">
              <input
                onChange={handleRoomChange}
                type="radio"
                name="roomType"
                id={d.id}
                value={d.id}
                dataname={d.roomType.roomTypeName}
                defaultChecked={d.roomType.roomTypeName === "Single Bed"}
                disabled={d.noOfBeds === d.occupiedBeds}
              />
              <label
                htmlFor={d.id}
                className={d.noOfBeds === d.occupiedBeds ? "occupied" : ""}
              >
                <div>
                  <Tooltip title={d.roomType.roomTypeName}>
                    <h3>{d.roomType.roomTypeName}</h3>
                  </Tooltip>
                  <Tooltip title={d.description}>
                    <p>{d.description}</p>
                  </Tooltip>
                </div>
                <div className="ro__icon">
                  <span className="mat-icon material-symbols-outlined">
                    {getRoomTypeIcon(d.roomType.roomTypeName)}
                  </span>
                  <div className="price">
                    <span></span>
                    <span>&#8377; {d.totalAmountWithTax}</span>
                    <span>/year</span>
                  </div>
                </div>
                <div className="ro__add-info">
                  <div>
                    <span>Security Deposit: </span>
                    <span>&#8377; {d.securityDeposit}</span>
                  </div>
                  <div>
                    <span>Available Rooms: </span>
                    <span>{d.noOfBeds - d.occupiedBeds}</span>
                  </div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
