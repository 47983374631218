import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./App.scss";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ReactGA from "react-ga4";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff6600",
    },
    secondary: {
      main: "#11cb5f",
    },
    text: {
      primary: "#ff6600",
      main: "#211a17",
      secondary: "#211a17b8",
      white: "#fff",
    },
  },
});
ReactGA.initialize("G-D7ZG440WGJ");
function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterConfig />
          <ScrollToTop />
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
reportWebVitals(SendAnalytics);
export default App;
