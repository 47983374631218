import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./ContactUs.scss";
import { Grid } from "@mui/material";
export function ContactUs() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="ContactUs">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="cu__top-toolbar">
            <BackButton />
          </div> */}
          <main className="cu__main-container">
            <section className="cu__left">
              <h2>Contact Us</h2>
              {/* <h4>Address :</h4>
              <p style={{ marginTop: "-2%" }}>
                12, Nirankar, 1133/5, Ferguson Road, Pune, Maharashtra 411016.
              </p> */}
              <h4>Phone Number :</h4>
              <p style={{ marginTop: "-2%" }}>8007005599 / 8888165599</p>
              <h4>Email :</h4>
              <p style={{ marginTop: "-2%" }}>support@myhostelroom.com</p>
            </section>
            <aside className="cu__right"></aside>
          </main>
        </Container>
      )}

      <Grid sx={{ mt: 20 }}>
        <Footer />
      </Grid>
    </div>
  );
}
