import React from "react";
import "./Share.scss";

export default function Share(props) {
  const bgColor = props.bgColor ? props.bgColor : "";
  const hostelName = props.hostelName ? props.hostelName : "";
  const hostelAddress = props.hostelAddress ? props.hostelAddress : "";
  const url = window.location.href;
  return (
    <div className="Share">
      <div className={`share__container ${bgColor}`}>
        <span className="material-symbols-outlined">
          {/* <a href={`https://web.whatsapp.com://send?Hostel Name=${hostelName} & Hostel Address = ${hostelAddress}` }   */}
        <a href={`whatsapp://send?text=Discover the perfect blend of comfort and convenience! 
        Check out this amazing hostel at MyHostelRoom! ${url}` }	data-action="share/whatsapp/share"
		    target="_blank"> share </a> 
         </span>
      </div>
    </div>
  );
}
