import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import "./ComplaintsAndFeedback.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogContentText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  TableFooter,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import {
  getUserComplaintsWithDetails,
  getCategoryAndSubcategory,
  getSubCategory,
  createComplaint,
} from "../../services/httpService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useToken from "../../services/useToken";
import environment from "../../config/environment";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import GppBadOutlinedIcon from "@mui/icons-material/GppBadOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

export function ComplaintsAndFeedback() {
  const navigate = useNavigate();
  const { token, userId } = useToken();
  const [complaints, setComplaints] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [openLodgeComplain, setOpenLodgeComplain] = useState(false);
  const [openViewComplain, setOpenViewComplain] = useState(false);
  const [complaintToView, setComplaintToView] = useState();
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetUserComplaints();
    handleGetCategory();
    console.log(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ",
      localStorage.getItem("bookingStatus")
    );
  }, [pageNo]);

  const handleGetUserComplaints = async () => {
    const userComplaints = await getUserComplaintsWithDetails({
      userId,
      token,
      pageNo,
      pageSize,
    });
    if (!userComplaints) {
    } else {
      console.log(">>>>>>>>>> USER COMPLAINTS", userComplaints);
      setComplaints(userComplaints?.content);
      setTotalPages(userComplaints?.totalPages);
      setIsLastPage(userComplaints?.lastPage);
      setIsLoading(false);
    }
  };

  const handleGetCategory = async () => {
    const category = await getCategoryAndSubcategory({
      token,
      pageNo,
      pageSize,
    });
    if (!category) {
    } else {
      setCategories(category.content);
    }
  };

  const handleLodgeComplain = () => {
    setOpenLodgeComplain(true);
  };

  const handleCloseLodgeComplain = () => {
    setOpenLodgeComplain(false);
    setSelectedCategory("");
    setSelectedSubCategory("");
  };

  const handleLodgeComplainSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const createComplaintResp = await createComplaint({
      token,
      body: {
        id: userId,
        categoryId: selectedCategory,
        subCategoryId: selectedSubCategory,
        assignedTo: null,
        description: description,
      },
    });
    if (!createComplaintResp) {
    } else {
      console.log(">>>>>>>>>> COMPLAINT CREATED", createComplaintResp);
      setOpenLodgeComplain(false);
      setSelectedCategory("");
      setSelectedSubCategory("");
      setDescription("");
      setIsLoading(false);
      window.location.reload();
    }
  };

  const handleCategorySelection = (e) => {
    console.log(">>>>>>>>>> CATEGORY SELECTION", e.target.value);
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    const selectedCategoryObj = categories.find(
      (category) => category.id === categoryId
    );

    console.log(">>>>>>>>>> SELECTED CATEGORY", selectedCategoryObj);

    setSubcategories(
      selectedCategoryObj ? selectedCategoryObj.subCategoryList : []
    );

    console.log(">>>>>>>>>> SUB CATEGORIES", subcategories);
  };

  const handleSubCategorySelection = (e) => {
    console.log(">>>>>>>>>> SUB CATEGORY SELECTION", e.target.value);
    const subCategoryId = e.target.value;
    setSelectedSubCategory(subCategoryId);
  };

  const handleDescriptionChange = (e) => {
    console.log(">>>>>>>>>> DESCRIPTION", e.target.value);
    setDescription(e.target.value);
  };

  const handleOpenViewComplain = (e, complaint) => {
    console.log(">>>>>>>>>> COMPLAINT", complaint);
    setComplaintToView(complaint);
    setOpenViewComplain(true);
  };

  const handleCloseViewComplain = () => {
    setOpenViewComplain(false);
  };

  const handleSkipToStart = () => {
    setPageNo(1);
  };

  const handleSkipToEnd = () => {
    setPageNo(totalPages);
  };

  const handleSkipToNext = () => {
    setPageNo(pageNo + 1);
  };

  const handleSkipToPrevious = () => {
    setPageNo(pageNo - 1);
  };

  const getStatusString = (status) => {
    console.log(">>>>>>>>>> STATUS", status);
    switch (status) {
      case "RECEIVED":
        return "COMPLAINT RECEIVED";
      case "ASSIGNED_TO_TEAM":
        return "ASSIGNED TO TEAM";
      case "MORE_DETAILS_NEEDED":
        return "MORE DETAILS NEEDED";
      case "RESOLVED":
        return "RESOLVED";
      case "SUBMITTED":
        return "SUBMITTED";
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="ComplaintsAndFeedback">
          <Header />
          <Navbar />
          <Grid xs={12} className="header">
            <Typography className="header__title">Complaints</Typography>
            <Button
              variant="contained"
              className="header__button"
              onClick={handleLodgeComplain}
              disabled={
                localStorage.getItem("bookingStatus") != "Tentative" &&
                localStorage.getItem("bookingStatus") != "Confirmed"
              }
            >
              Lodge a Complaint
            </Button>
          </Grid>
          <TableContainer
            component={Paper}
            style={{
              width: "70%",
              height: "500px",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              margin: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="100" align="left">
                    Date
                  </TableCell>

                  <TableCell width="100" align="left">
                    Category
                  </TableCell>
                  <TableCell width="100" align="left">
                    Sub-Category
                  </TableCell>
                  <TableCell width="100" align="left">
                    Description
                  </TableCell>

                  <TableCell width="100" align="left">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complaints &&
                  complaints.map(
                    (complaint) => (
                      console.log(">>>>>>>>>> COMPLAINT", complaint),
                      (
                        <TableRow
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                          onClick={(e) => handleOpenViewComplain(e, complaint)}
                        >
                          <TableCell
                            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                            align="left"
                          >
                            {complaint?.createdAt}
                          </TableCell>
                          <TableCell
                            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                            align="left"
                          >
                            {complaint?.complaintCategory?.categoryName}
                          </TableCell>
                          <TableCell
                            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                            align="left"
                          >
                            {complaint?.complaintSubCategory?.subCategoryName}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "rgba(0, 0, 0, 0.7)",
                              maxWidth: 100,
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            align="left"
                          >
                            {complaint?.description}
                          </TableCell>

                          <TableCell
                            sx={{ color: "rgba(0, 0, 0, 0.7)" }}
                            align="left"
                          >
                            {getStatusString(
                              complaint?.userComplaintDetailsList.length > 0
                                ? complaint?.userComplaintDetailsList[0]?.status
                                : "SUBMITTED"
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            sx={{
              display: "flex",
              width: "70%",
              height: "40px",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderTop: "none",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
              margin: "auto",
              paddingTop: "5px",
            }}
          >
            {/* <Button
              disabled={pageNo == 1}
              sx={{
                width: "30px",
                height: "30px",
                fontSize: "x-large",
              }}
              onClick={handleSkipToStart}
            >
              &lt;&lt;
            </Button> */}
            <IconButton disabled={pageNo == 1} onClick={handleSkipToStart}>
              <FirstPageIcon />
            </IconButton>
            <IconButton disabled={pageNo == 1} onClick={handleSkipToPrevious}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography sx={{ padding: "5px" }}>{pageNo}</Typography>
            <IconButton disabled={isLastPage} onClick={handleSkipToNext}>
              <KeyboardArrowRight />
            </IconButton>
            <IconButton disabled={isLastPage} onClick={handleSkipToEnd}>
              <LastPageIcon />
            </IconButton>
          </Grid>

          <Dialog
            maxWidth="xl"
            open={openLodgeComplain}
            onClose={handleCloseLodgeComplain}
          >
            <form onSubmit={handleLodgeComplainSubmit}>
              <DialogTitle>Lodge a Complaint</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fill in the below form to lodge a complain
                </DialogContentText>

                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: "10px", width: "600px" }}
                >
                  <FormControl fullWidth required sx={{ marginTop: "20px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      label="Category"
                      placeholder="Category"
                      value={selectedCategory}
                      onChange={(e) => {
                        handleCategorySelection(e);
                      }}
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      {categories &&
                        categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.categoryName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    required
                    sx={{ marginTop: "20px" }}
                    disabled={!selectedCategory}
                  >
                    <InputLabel>Sub-category</InputLabel>
                    <Select
                      label="Sub-Category"
                      placeholder="Sub-Category"
                      value={selectedSubCategory}
                      onChange={(e) => {
                        handleSubCategorySelection(e);
                      }}
                      sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        "& .MuiSvgIcon-root": {
                          color: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      {subcategories.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                          {subcategory.subCategoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    label="Description"
                    placeholder="Enter Details of your problem"
                    required
                    variant="outlined"
                    type="text"
                    className="component__textbox-big"
                    sx={{ marginTop: "20px" }}
                    onChange={(e) => {
                      handleDescriptionChange(e);
                    }}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseLodgeComplain}>Close</Button>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>

          <Dialog
            maxWidth="xl"
            open={openViewComplain}
            onClose={handleCloseViewComplain}
          >
            <form>
              <DialogTitle>#{complaintToView?.complaintNo}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  View the details of your complain.
                </DialogContentText>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "600px",
                    marginTop: "10px",
                    paddingBottom: "15px",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "25px",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#ff6600",
                        }}
                      >
                        Complaint date:
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography className="viewComplain__value">
                        {complaintToView?.createdAt}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "25px",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#ff6600",
                        }}
                      >
                        Category:
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography className="viewComplain__value">
                        {complaintToView?.complaintCategory?.categoryName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "25px",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#ff6600",
                        }}
                      >
                        Sub-Category:
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography className="viewComplain__value">
                        {complaintToView?.complaintSubCategory?.subCategoryName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "25px",
                      paddingTop: "15px",
                    }}
                  >
                    <Grid xs={4}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "#ff6600",
                        }}
                      >
                        Description:
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography className="viewComplain__value">
                        {complaintToView?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {complaintToView?.userComplaintDetailsList &&
                  complaintToView?.userComplaintDetailsList?.map((details) => (
                    <>
                      <div
                        className="line"
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                      ></div>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "600px",
                          marginTop: "10px",
                          paddingBottom: "15px",
                          borderRadius: "5px",
                          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: "25px",
                            paddingTop: "15px",
                          }}
                        >
                          <Grid xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "#ff6600",
                              }}
                            >
                              Status:
                            </Typography>
                          </Grid>
                          <Grid xs={8}>
                            <Typography className="viewComplain__value">
                              {getStatusString(details?.status)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: "25px",
                            paddingTop: "15px",
                          }}
                        >
                          <Grid xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "#ff6600",
                              }}
                            >
                              Status Date:
                            </Typography>
                          </Grid>
                          <Grid xs={8}>
                            <Typography className="viewComplain__value">
                              {details?.createdDateTime.split(" ")[0]}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingLeft: "25px",
                            paddingTop: "15px",
                          }}
                        >
                          <Grid xs={4}>
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "18px",
                                color: "#ff6600",
                              }}
                            >
                              Comments:
                            </Typography>
                          </Grid>
                          <Grid xs={8}>
                            <Typography className="viewComplain__value">
                              {details?.comments}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseViewComplain}>Close</Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      )}
    </div>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
