import React, { useState, useEffect } from "react";
import "./AmountToPayNow.scss";

export function AmountToPayNow(props) {
  const [data, setData] = useState(props?.data);
  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  return (
    <div className="AmountToPayNow">
      <div className="bd__payment-summary">
        <h3>Amount to Pay Now</h3>
        <div className="bd__payment-summary-calc">
          <div>
            <div>Booking Amount</div>
            <div>&#8377; {data?.paymentEmiDetails[0].rent + data?.paymentEmiDetails[0].securityDeposit}</div>
          </div>

          {/* {data?.paymentEmiDetails[0].tax.map((d, i) => (
            <div key={i}>
              <div>
                {d.taxName}
                {" @ "}
                {d.percentage}
              </div>
              <div>&#8377; {d.taxAmount}</div>
            </div>
          ))} */}

          <div className="bd__payment-summary-gt">
            <div>Total</div>
            <div>&#8377; {data?.paymentEmiDetails[0].grandTotal}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
