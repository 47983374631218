import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import "./FAQs.scss";
import {
 getFAQs
} from "../../services/httpService";
import Parser from "html-react-parser";

export function FAQs() {
  const [loading, setLoading] = useState(true);
  const [faqList, setFaqList] = useState([]);
  useEffect(() => {
    setLoading(true);
    handleFAQs();
    setLoading(false);
  }, []);

  const handleFAQs = async (val) => {
    const po = await getFAQs(val);
    if (po) {
      setFaqList(po?.response?.content);
    }
  };

  return (
    <div className="FAQs">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="au__top-toolbar">
            <BackButton />
          </div> */}
          <main className="au__main-container">
            <section className="au__left">
              <h2 style={{ color: "#ff6600" }}>FAQs</h2>
              <List className="bd__tnc-list">
                {faqList?.map((d, i) => (
                  <div>
                    <Typography
                      sx={{
                        marginBottom: "-1%",
                        flexShrink: 0,
                      }}
                    >
                     <h4>{d.question}</h4> 
                    </Typography>
                    <ListItem>
                      <ListItemText primary={Parser(d.answer)} />
                    </ListItem>
                  </div>
                ))}
              </List>
            </section>
            <aside className="au__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
