import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Tooltip } from "@mui/material";
import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import PropTypes from "prop-types";
import environment from "../../../../config/environment";
import "./ViewInMap.scss";

export function ViewInMap(props) {
  const { onClose, hostelData, open } = props;
  const navigate = useNavigate();
  const loader = new Loader({
    apiKey: environment.gMapKey,
    version: "weekly",
  });

  useEffect(() => {
    if (open) {
      let map;
      const center = { lat: 18.5424, lng: 73.8567 };
      const zoom = 12;

      loader.load().then(() => {
        map = new window.google.maps.Map(document.getElementById("viewInMap"), {
          center,
          zoom,
          mapTypeControl: false,
          disableDefaultUI: true,
        });

        const markers = hostelData.map((d, i) => {
          const position = { lat: d.latitude, lng: d.longitude };
          const marker = new window.google.maps.Marker({
            position,
            icon: "/images/marker.png",
          });
          const infowindow = new window.google.maps.InfoWindow({
            content: "",
          });

          marker.addListener("click", () => {
            infowindow.setContent(`<h3>${d.hostelName}</h3>`);
            infowindow.open(map, marker);
          });

          const hn = document.getElementById(`hostel${d.id}`);

          hn.addEventListener("click", () => {
            infowindow.setContent(`<h3>${d.hostelName}</h3>`);
            infowindow.open(map, marker);
          });
          return marker;
        });

        new MarkerClusterer({ markers, map });
      });
    }
  }, [open]);

  const handleOpenHostel = (val) => {
    const { hostelName, id } = val;
    const hn = hostelName.replace(/\s/g, "_");
    navigate(`/hostel-details/${hn}/${id}`);
  };

  return (
    <Dialog
      className="HL-ViewInMap"
      maxWidth="xl"
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <div className="hl-vim__map-container">
          <div id="viewInMap" style={{ width: "100%", height: "100%" }}></div>
          <div className="hl-vim__hl-container">
            {hostelData.map((d) => {
              return (
                <div key={d.id} className="hl-vim__hl-tile">
                  <div
                    className="hl-vim__img-container"
                    style={{
                      backgroundImage: `url("${
                        d?.imageAttachment.length > 0
                          ? d?.imageAttachment[0].imagePath
                          : "/images/image_not_available.png"
                      }")`,
                    }}
                  ></div>
                  <div className="hl-vim__name-container">
                    <div className="hl-vim__name">
                      <h3 id={`hostel${d.id}`}>{d.hostelName}</h3>
                    </div>
                    <div className="hl-vim__location">
                      <Tooltip title={d.hostelAddress}>
                        <p>
                          <span className="mat-icon material-symbols-outlined">
                            location_on
                          </span>
                          <span>{d.hostelAddress}</span>
                        </p>
                      </Tooltip>
                    </div>
                    <div className="hl-vim__rating">
                      <span>
                        <span className="mat-icon material-symbols-outlined">
                          star_rate
                        </span>
                        <span>4.5</span>
                      </span>
                      <span
                        className="mat-icon material-symbols-outlined"
                        onClick={() => handleOpenHostel(d)}
                      >
                        arrow_right_alt
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="hl-vim__close" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

ViewInMap.propTypes = {
  open: PropTypes.bool.isRequired,
};
