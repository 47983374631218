import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./TermsOfUse.scss";

export function TermsOfUse() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="TermsOfUse">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="tou__top-toolbar">
            <BackButton />
          </div> */}
          <main className="tou__main-container">
            <section className="tou__left">
              <h2 style={{ color: "#ff6600" }}>Terms of Use</h2>
              <h4>1. Access to the Portal</h4>
              <ul>
                <li>
                  <span color="#000">1.1 Eligibility:</span> You must be at
                  least 18 years old to access and use the Portal.
                </li>
                <li>
                  1.2 Registration: In order to access certain features of the
                  Portal, you may be required to create an account and provide
                  accurate and complete information.
                </li>
                <li>
                  1.3 Account Security: You are responsible for maintaining the
                  confidentiality of your account credentials and for all
                  activities that occur under your account.
                </li>
              </ul>
              <h4>2. Use of the Portal</h4>
              <ul>
                <li>
                  2.1 Permitted Use: You may use the Portal solely for your
                  personal, non-commercial purposes, in compliance with these
                  Terms and any applicable laws or regulations.
                </li>
                <li>
                  2.2 Prohibited Conduct: You agree not to engage in any of the
                  following activities: <br />
                  a. Violating any applicable laws or regulations.
                  <br /> b. Interfering with or disrupting the operation of the
                  Portal.
                  <br /> c. Uploading, posting, or transmitting any content that
                  is unlawful, harmful, defamatory, abusive, obscene, or
                  otherwise objectionable.
                  <br /> d. Impersonating any person or entity or falsely
                  stating or misrepresenting your affiliation with a person or
                  entity. <br />
                  e. Collecting or storing personal information of other users
                  without their consent.
                </li>
              </ul>
              <h4>3. Intellectual Property</h4>
              <ul>
                <li>
                  3.1 Ownership: All intellectual property rights in the Portal
                  and its contents (including text, graphics, logos, and
                  software) are owned by us or our licensors.
                </li>
                <li>
                  3.2 Limited License: We grant you a limited, non-exclusive,
                  non-transferable license to access and use the Portal solely
                  for the purposes set forth in these Terms.
                </li>
                <li>
                  3.3 Restrictions: You agree not to modify, reproduce,
                  distribute, create derivative works, or exploit any part of
                  the Portal without our prior written consent.
                </li>
              </ul>
              <h4>4. Privacy</h4>
              <ul>
                <li>
                  Our Privacy Policy explains how we collect, use, and disclose
                  information about you. By using the Portal, you consent to our
                  collection, use, and disclosure of your information as
                  described in the Privacy Policy.
                </li>
              </ul>
              <h4>5. Disclaimer of Warranties</h4>
              <ul>
                <li>
                  The Portal is provided on an "as is" and "as available" basis,
                  without warranties of any kind, whether express or implied. We
                  disclaim all warranties, including, but not limited to,
                  implied warranties of merchantability, fitness for a
                  particular purpose, and non-infringement.
                </li>
              </ul>
              <h4>6. Limitation of Liability</h4>
              <ul>
                <li>
                  In no event shall we be liable for any indirect, incidental,
                  special, consequential, or exemplary damages arising out of or
                  in connection with the Portal or these Terms, whether based on
                  warranty, contract, tort (including negligence), or any other
                  legal theory.
                </li>
              </ul>
              <h4>7. Modifications</h4>
              <ul>
                <li>
                  We reserve the right to modify or terminate the Portal or
                  these Terms at any time, without prior notice. It is your
                  responsibility to review these Terms periodically for any
                  changes.
                </li>
              </ul>
              <h4>8. Governing Law and Jurisdiction</h4>
              <ul>
                <li>
                  These Terms shall be governed by and construed in accordance
                  with the laws of Indian Jurisdiction. Any disputes arising out
                  of or in connection with these Terms shall be subject to the
                  exclusive jurisdiction of the courts of Indian Jurisdiction.
                </li>
              </ul>
              <h4>9. Severability</h4>
              <ul>
                <li>
                  If any provision of these Terms is found to be unenforceable
                  or invalid, that provision shall be limited or eliminated to
                  the minimum extent necessary, and the remaining provisions
                  shall remain in full force and effect.
                </li>
              </ul>
              <h4>10. Entire Agreement</h4>
              <ul>
                <li>
                  These Terms constitute the entire agreement between you and us
                  regarding the Portal and supersede all prior or
                  contemporaneous understandings or agreements, whether oral or
                  written.
                </li>
              </ul>
              <h4>11. Cancellation and Refund Policy</h4>
              <ul>
                <li>
                  All cancellations before 30 days to the Check-in date are
                  eligible for full refund.
                </li>
                <li>
                  Cancellation within 30 days will process full refund of
                  Security deposit &amp; Rent along with GST there on with a
                  standard deduction of full reservation amount paid at the time
                  of booking.
                </li>
                <li>
                  2.5% Card payment or bank charges will not be included in
                  refund amount.
                </li>
                <li>
                  Any cancellation within or from 5 days to Check-in date will
                  process 50% of refund amount.
                </li>
                <li>
                  Cancellation within 5 days will process full refund of
                  Security deposit &amp; 50% of Rent along with proportionate
                  GST there on.
                </li>
                <li>
                  No refund of rent along with GST there on will be payable once
                  checked-in. Security deposit will be refundable as per company
                  policy.
                </li>
              </ul>
            </section>
            <aside className="tou__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
