import React from "react";
import { useNavigate } from "react-router-dom";
import "./BackButton.scss";

export default function BackButton(props) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  }

  return (
    <div className="BackButton">
      <div className="bb__container" onClick={handleBackClick}>
        <span className="mat-icon material-symbols-outlined">arrow_back</span>
        <span>Back</span>
      </div>
    </div>
  );
}
