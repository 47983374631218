import React, { useState, useEffect } from "react";
import "./BookingSummary.scss";

export function BookingSummary(props) {
  return (
    <div className="BookingSummary">
      <div className="bd__booking-summary">
        <h3>Booking Summary</h3>
        <div className="bd__booking-summary-list">
          <div>
            <div>Room Type</div>
            <div>{props?.roomTypeName}</div>
          </div>
          <div>
            <div>Start Date</div>
            <div>{props?.moveInDate}</div>
          </div>
          <div>
            <div>Duration of stay</div>
            <div>{props?.stayDuration} months</div>
            {/* <div>12 months</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
