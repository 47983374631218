import environment from "../config/environment";

const authenticateUser = async (credentials) => {
  const apiEndpoint = `${environment.api}/api/v1/authenticate`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(credentials),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const setPassword = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/set-password`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const resetPassword = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/reset-password`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const updateProfilePic = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/upload-profile-pic/${args.userId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: args.body,
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.text();
    return result;
  } catch (error) {
    console.log("API REEOE: ",error);
  }
}

const getUserDetail = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/user/${args.userId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getUserComplaintsWithDetails = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/user-complaint-list-with-details?pageNo=${args.pageNo}&pageSize=${args.pageSize}&userId=${args.userId}&sortBy=id`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result.response;
  } catch (error) {
    console.log(error);
  }
};

const getCategoryAndSubcategory = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/complaint-category-with-sub-category-list?pageNo=${args.pageNo}&pageSize=${args.pageSize}&active=true`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result.response;
  } catch (error) {
    console.log(error);
  }
};

const getSubCategory = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/complaint-sub-category-list?pageNo=${args.pageNo}&pageSize=${args.pageSize}&active=true&complaintCategoryId=${args.categoryId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result.response;
  } catch (error) {
    console.log(error);
  }
};

const createComplaint = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/user-complaint`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const updateUserDetail = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/update-user/${args.userId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const updatePersonalDetails = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/student-details`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

const updateGuardianDetails = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/student-guardian-details`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
}

const getHostelList = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/hostels-with-pagination?pageNo=1&pageSize=10&sortBy=id&sortDir=desc&currentLat=${args.currentLat}&currentLng=${args.currentLng}&status=Completed`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getImageGallery = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/hostel-image-attachment-mapping/${args.hostelId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getHostelRules = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/hostel-rule-mapping/${args.hostelId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getHostelData = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/hostel/${args.hostelId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const payAndBook = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/book-now`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getPaymentDetail = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/payment-summary/${args.hostelRoomId}?stayOfDuration=${args.stayDuration}&payOption=${args.payDuration}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          args.token !== "" ? `Bearer ${args.token.replace(/"/g, "")}` : "",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getEmiSummary = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/generate-emi-summary/${args.hostelRoomId}?&payOption=${args.payDuration}&startDate=${args.startDate}&stayDuration=${args.stayDuration}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          args.token !== "" ? `Bearer ${args.token.replace(/"/g, "")}` : "",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    alert("Contact to MyHostelRoom team for this booking!");
  }
};

const downloadReceiptVoucher = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/receipt-voucher-pdf?paymentHistoryId=${args.paymentHistoryId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization:
          args.token !== "" ? `Bearer ${args.token.replace(/"/g, "")}` : "",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getPayOption = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/pay-option-list/${args}?isDisplay=true`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const onPaymentSuccess = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/update-booking-payment`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const onPaymentFailure = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/update-booking-payment`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getUserBookings = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/my-booking-list/${args.userId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log("result", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getTemsConditions = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/terms-and-condition-list?active=true`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getFAQs = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/faqs?active=true&pageSize=100`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const verifyCaptcha = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/verify-recaptcha?token=${args.captchaToken}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const addMakeEnquiry = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/user-query`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(args.body),
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getRoomDetails = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/hostel-with-room-details/${args.hostelId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const getMobileOtp = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/generate-otp?mobileNo=${args.mobile}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const verifyMobileOtp = async (args) => {
  console.log("args", args);
  const apiEndpoint = `${environment.api}/api/v1/verify-mobile-otp?userId=${args.userId}&otp=${args.mobileotp}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

const payNextEmi = async (args) => {
  const apiEndpoint = `${environment.api}/api/v1/pay-next-payment/${args.paymentHistoryId}`;
  try {
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${args.token.replace(/"/g, "")}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.text();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export {
  authenticateUser,
  getUserDetail,
  getHostelList,
  getImageGallery,
  getHostelRules,
  getHostelData,
  payAndBook,
  getPaymentDetail,
  getPayOption,
  onPaymentSuccess,
  onPaymentFailure,
  getUserBookings,
  setPassword,
  resetPassword,
  updateUserDetail,
  updatePersonalDetails,
  updateGuardianDetails,
  updateProfilePic,
  getTemsConditions,
  getFAQs,
  getEmiSummary,
  verifyCaptcha,
  addMakeEnquiry,
  getRoomDetails,
  getMobileOtp,
  verifyMobileOtp,
  payNextEmi,
  downloadReceiptVoucher,
  getUserComplaintsWithDetails,
  getCategoryAndSubcategory,
  getSubCategory,
  createComplaint,
};
