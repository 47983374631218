import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import "./BookingFailed.scss";
import { Link } from "react-router-dom";

export function BookingFailed() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="BookingFailed">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <main className="bf__main-container">
            <span className="mat-icon material-symbols-outlined">error</span>
            <h2>Payment Unsuccessful</h2>
            <p>Please try again after some time.</p>
            <p>
              Or, for any other issues please contact us as below:
              <br /> <br />
              Call us @: <strong>8007005599 / 8888165599</strong>
              <br />
              Email us @: <strong>sales@myhostelroom.com </strong>
            </p>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
