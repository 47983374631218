import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./HelpSupport.scss";

export function HelpSupport() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="HelpSupport">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <div className="up__top-toolbar">
            <BackButton path="/" />
          </div>
          <main className="up__main-container">
            <section className="up__left">
              <h2>Help &amp; Support</h2>
              <div className="up__main">
                <p>This page is under construction.</p>
              </div>
            </section>
            <aside className="up__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
