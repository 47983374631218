import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Container } from "@mui/system";

export default function Loader(props) {
  const loaderStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "ceneter",
    minHeight: "calc(100vh - 186px)",
    textAlign: "center"
  };
  return (
    <div style={loaderStyle}>
      <Container>
        <div className="component__loader">
          <CircularProgress />
        </div>
      </Container>
    </div>
  );
}
