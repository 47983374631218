import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import { getImageGallery } from "../../../../services/httpService";
import "./ImageGallery.scss";

export function ImageGallery(props) {
  const [imageGallery, setImageGallery] = useState([]);
  const [imageSliderOpen, setImageSliderOpen] = useState(false);
  const imageSliderClose = () => {
    setImageSliderOpen(false);
  };
  const handleOpen = () => {
    setImageSliderOpen(true);
  };

  let imageGallery1 = [],
    imageGallery2 = [];

  useEffect(() => {
    const handleGetImageGallery = async () => {
      const result = await getImageGallery({
        hostelId: props?.id,
      });
      if (result) {
        setImageGallery(result.response);
      }
    };
    handleGetImageGallery();
  }, []);

  imageGallery1 = imageGallery.slice(0, 3);
  imageGallery2 = imageGallery.slice(3, 6);

  return (
    <div className="ImageGallery">
      <div className="hd__img-container" onClick={handleOpen}>
        <div className="hd__img-container-mobile">
          {imageGallery.map((d, i) => (
            <img key={i} src={d.imageAttachment.imagePath} alt="" />
          ))}
        </div>
        <div className="hd__img-container-left">
          {imageGallery1.map((d, i) => (
            <img key={i} src={d.imageAttachment.imagePath} alt="" />
          ))}
        </div>
        <div className="hd__img-container-right">
          {imageGallery2.map((d, i) => (
            <img key={i} src={d.imageAttachment.imagePath} alt="" />
          ))}
        </div>
      </div>
      {imageGallery.length > 0 ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={imageSliderOpen}
          onClick={imageSliderClose}
        >
          <ImageSlider
            images={imageGallery}
            imageSliderOpen={imageSliderOpen}
          />
        </Backdrop>
      ) : (
        ""
      )}
    </div>
  );
}

const ImageSlider = (props) => {
  const sliderGallery = props?.images;
  let slideIndex = 1;
  const showSlides = async (n) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex - 1].style.display = "block";
  };

  useEffect(() => {
    if (props.imageSliderOpen) {
      showSlides(slideIndex);
    }
  }, []);

  function plusSlides(n) {
    showSlides((slideIndex += n));
  }

  return (
    <>
      <div
        className="slideshow-container"
        onClick={($event) => {
          $event.stopPropagation();
        }}
      >
        {sliderGallery?.map((d, i) => {
          return (
            <div className="mySlides fade" key={i}>
              <div className="numbertext">
                <span>{i + 1}</span> / {sliderGallery?.length}
              </div>
              <img
                src={d.imageAttachment.imagePath}
                style={{ width: "100%" }}
                alt={d?.imageAttachment.imageText}
              />
              <div className="text">{d?.imageAttachment.imageText}</div>
            </div>
          );
        })}

        <span className="prev" onClick={() => plusSlides(-1)}>
          &#10094;
        </span>
        <span className="next" onClick={() => plusSlides(1)}>
          &#10095;
        </span>
      </div>

      {/* <div style={{ textAlign: "center" }}>
        <span className="dot" onClick={() => currentSlide(1)}></span>
        <span className="dot" onClick="currentSlide(2)"></span>
        <span className="dot" onClick="currentSlide(3)"></span>
      </div> */}
    </>
  );
};
