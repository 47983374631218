import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Button from "@mui/material/Button";
import { LeftSection } from "../components/left-section/LeftSection";
import "./Register.scss";
import environment from "../../config/environment";
import { TextField, Tooltip } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import useToken from "../../services/useToken";

export function Register() {
  const { setToken } = useToken();
  const [registerStep, setRegisterStep] = useState(1);
  const [isGetOtpDisabled, setGetOtpDisabled] = useState(false);
  const [isRegisterUserDisabled, setRegisterUserDisabled] = useState(false);
  const [isVerifyEmailDisabled, setVerifyEMailDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(" ");
  const [passwordHelperText, setPasswordHelperText] = useState(" ");
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [redirectTime, setRedirectTime] = useState("6");
  const navigate = useNavigate();
  const [snackBarSeverity, setSnackBarSeverity] = useState("");

  // const getOtp = async (credentials) => {
  //   const apiEndpoint = `${environment.api}/api/v1/send-otp?email=${credentials.email}&isNewUser=true`;
  //   try {
  //     const response = await fetch(apiEndpoint, {
  //       method: "POST",
  //       cache: "no-cache",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     });
  //     if (!response.ok) {
  //       console.log('response',response.json());
  //       setErrorMsg("User Details Already exist");
  //       setOpenSnackBar(true);
  //       throw new Error(`Error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     return result;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getOtp = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/send-otp?email=${credentials.email}&isNewUser=true`;
    fetch(apiEndpoint, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        response
          .json()
          .then((j) => {
            console.log("Response: ", j);
            if (j.status==200) {
              console.log("Success ==== : ",j)
              setErrorMsg(j.message);
              setSnackBarSeverity("success");
              setOpenSnackBar(true);
              setRegisterStep(2);
            }
            else {
              console.log("Error ==== : ",j)
              setErrorMsg(j.message);
              setSnackBarSeverity("error");
              setOpenSnackBar(true);
              setGetOtpDisabled(false);
            }
          })
          .catch((e) => {
            console.log("error =======", e);
          });
      })
      .catch((e) => console.log(e));
  };

  const verifyOtp = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/verify-otp?email=${credentials.email}&otp=${credentials.otp}`;
    try {
      const response = await fetch(apiEndpoint, {
        method: "PUT",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const registerUser = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/signup`;
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGetOtp = async (event) => {
    setGetOtpDisabled(true);
    event.preventDefault();
    await getOtp({
      email,
    })
    // .then((token) => {
    //   console.log("token------->", token);
    //   if (token.status != 200) {
    //     console.log("****", token);
    //     setErrorMsg(token.message);
    //     setOpenSnackBar(true);
    //     setGetOtpDisabled(false);
    //   } else {
    //     console.log("@@@@", token);
    //     setRegisterStep(2);
    //   }
    // });
  };

  const handleVerifyOtp = async (event) => {
    setVerifyEMailDisabled(true);
    event.preventDefault();
    const verifyEmailResponse = await verifyOtp({
      email,
      otp,
    });

    if (!verifyEmailResponse) {
      setVerifyEMailDisabled(false);
      setErrorMsg("Incorrect OTP");
      setSnackBarSeverity("error");
      setOpenSnackBar(true);
    } else {
      setRegisterStep(3);
    }
  };

  const handleRegister = async (event) => {
    setRegisterUserDisabled(true);
    event.preventDefault();
    const registeredUser = await registerUser({
      id: null,
      fullName,
      username: email,
      password,
      mobileNo: mobile,
      userDetailsId: null,
      roleId: null,
      imageAttachmentId: null,
    });

    if (!registeredUser) {
      setRegisterUserDisabled(false);
    } else {
      setToken(registeredUser);
      setRegisterStep(4);
      redirectTimer();
    }
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };

  const handleOtp1 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp1(val);
    document.querySelector(".otp2 input").focus();
  };

  const handleOtp2 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp2(val);
    document.querySelector(".otp3 input").focus();
  };

  const handleOtp3 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp3(val);
    document.querySelector(".otp4 input").focus();
  };

  const handleOtp4 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp4(val);
    setOtp(`${otp1}${otp2}${otp3}${val}`);
  };

  const redirectTimer = () => {
    let t = 5;
    setInterval(() => {
      if (t >= 0) {
        setRedirectTime(t);
        t--;
      }
    }, 1000);
    setTimeout(() => {
      navigate("/login");
    }, 6000);
  };

  return (
    <>
      <div className="Authentication Register">
        <div className="auth__main">
          <LeftSection imgName="room2" />
          <section className="auth__right-section">
            <div></div>
            <div className="auth__welcome-msg">
              <Typography variant="h4" color="text.secondary" align="center">
                Register
              </Typography>
              <Typography variant="body2" color="text.main" align="center">
                Create a new account
              </Typography>
            </div>
            {registerStep === 1 ? (
              <form
                autoComplete="off"
                onSubmit={handleGetOtp}
                className="auth__form-container"
              >
                <div className="auth__form">
                  <TextField
                    label="Email Id"
                    placeholder="user@email.com"
                    className="component__textbox-big"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    variant="outlined"
                    type="email"
                    value={email}
                    error={emailError}
                    helperText={emailHelperText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div>
                  <Button
                    disabled={isGetOtpDisabled}
                    type="submit"
                    className="auth__btn"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </div>
              </form>
            ) : (
              ""
            )}

            {registerStep === 2 ? (
              <form
                autoComplete="off"
                onSubmit={handleVerifyOtp}
                className="auth__form-container"
              >
                <div className="auth__form auth__step2">
                  <h4>Enter OTP sent on your email</h4>
                  <div className="auth__step2-otp">
                    <TextField
                      className="component__textbox-otp otp1"
                      onChange={(e) => handleOtp1(e.target.value)}
                      required
                      variant="outlined"
                      type="text"
                      autoFocus={true}
                      value={otp1}
                    />
                    <TextField
                      className="component__textbox-otp otp2"
                      onChange={(e) => handleOtp2(e.target.value)}
                      required
                      variant="outlined"
                      type="text"
                      value={otp2}
                    />
                    <TextField
                      className="component__textbox-otp otp3"
                      onChange={(e) => handleOtp3(e.target.value)}
                      required
                      variant="outlined"
                      type="text"
                      value={otp3}
                    />
                    <TextField
                      className="component__textbox-otp otp4"
                      onChange={(e) => handleOtp4(e.target.value)}
                      required
                      variant="outlined"
                      type="text"
                      value={otp4}
                    />
                  </div>
                </div>
                <div>
                  <Button
                    disabled={isVerifyEmailDisabled}
                    type="submit"
                    className="auth__btn"
                    variant="contained"
                  >
                    Verify Email
                  </Button>
                </div>
              </form>
            ) : (
              ""
            )}

            {registerStep === 3 ? (
              <form
                autoComplete="off"
                onSubmit={handleRegister}
                className="auth__form-container"
              >
                <div className="auth__form">
                  <TextField
                    label="Email"
                    className="component__textbox-big"
                    required
                    disabled
                    variant="filled"
                    type="text"
                    value={email}
                    helperText=" "
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Full Name"
                    placeholder="Enter your full name"
                    className="component__textbox-big"
                    onChange={(e) => setFullName(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    value={fullName}
                    helperText={emailHelperText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label="Mobile Number"
                    placeholder="9876543210"
                    className="component__textbox-big"
                    onChange={(e) => setMobile(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    value={mobile}
                    error={emailError}
                    helperText={emailHelperText}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalPhoneOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    label="Password"
                    placeholder="**********"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={passwordError}
                    className="component__textbox-big"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Tooltip
                    title="Password must be 8-16 characters with at-least 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character"
                    className="register__pwd-help"
                  >
                    <span style={{ color: "#ff6600" }}>Password rules</span>
                  </Tooltip>
                </div>

                <div>
                  <Button
                    disabled={isRegisterUserDisabled}
                    type="submit"
                    className="auth__btn"
                    variant="contained"
                  >
                    Register
                  </Button>
                </div>
              </form>
            ) : (
              ""
            )}

            {registerStep === 4 ? (
              <div className="auth__step4">
                <span className="mat-icon material-symbols-outlined">
                  verified
                </span>
                <h3>Registration Successful</h3>
                <h4>Please login to access your account.</h4>
                <p>Redirecting in {redirectTime} sec...</p>
              </div>
            ) : (
              ""
            )}

            {/* <div className="auth__social">
              <div className="auth__social-or">
                <div></div>
                <div>or</div>
              </div>
              <div className="auth__social-google">
                <img src="/images/google-icon.png" />
                <span>Continue with Google</span>
              </div>
            </div> */}
            <div></div>
            <div className="auth__footer">
              <div>
                <p>Already have an account?</p>
                <Link to="/login">Login Here</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
