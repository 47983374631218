import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import {
  Divider,
  Tooltip,
  Chip,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Drawer,
} from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Favourite from "../../components/favourite/Favourite";
import { ViewInMap } from "./components/ViewInMap/ViewInMap";
import Loader from "../../components/loader/Loader";
import { Facilities } from "./components/Facilities/Facilities";
import BackButton from "../../components/back-button/BackButton";
import SortIcon from "@mui/icons-material/Sort";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { getHostelList } from "../../services/httpService";
import "./HostelListPage.scss";

export function HostelListPage(props) {
  const [loading, setLoading] = useState(true);
  const [hostelList, setHostelList] = useState([]);
  const [hostelListData, setHostelListData] = useState([]);
  const [hostelCount, setHostelCount] = useState(0);
  const [openMapView, setOpenMapView] = useState(false);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [filterGender, setFilterGender] = useState("");
  const { place } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLat = location.state.lat;
  const currentLng = location.state.lng;
  const searchTerm = place.substring(10).replace(/_/g, " ") || "";

  const handleOpenMapView = () => {
    setOpenMapView(true);
  };

  const handleCloseMapView = () => {
    setOpenMapView(false);
  };

  const handleOpenHostelDetail = (hostel) => {
    const hn = hostel?.hostelName.replace(/\s/g, "_");
    navigate(`/hostel-details/${hn}/${hostel.id}`);
  };

  const handleFilterGender = (gender) => {
    setFilterGender(gender);
    const filteredHostelList = hostelListData.filter((d) => {
      return d.allowedGenderType.toLowerCase() === gender;
    });
    setHostelList(filteredHostelList);
    console.log(filteredHostelList);
  };

  const handleClearFilterGender = () => {
    setFilterGender("");
    setHostelList(hostelListData);
  };

  const handleOpenMobileFilter = () => {
    setIsMobileFilterOpen(true);
  };

  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  useEffect(() => {
    const handleGetHostelList = async () => {
      const hl = await getHostelList({
        currentLat,
        currentLng,
      });
      if (hl) {
        setHostelList(hl?.response.content);
        setHostelListData(hl?.response.content);
        setHostelCount(hl?.response.totalElements);
        setLoading(false);
      }
    };
    handleGetHostelList();
  }, []);

  return (
    <div className="HostelListPage">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="hl__top-toolbar">
            <BackButton />
            <Breadcrumb />
          </div> */}
          <main className="hl__main-container">
            <section className="hl__left">
              <div className="hl__view-on-map">
                <Button variant="contained" onClick={handleOpenMapView}>
                  View on map
                </Button>
                <ViewInMap
                  hostelData={hostelList}
                  open={openMapView}
                  onClose={handleCloseMapView}
                />
              </div>
              <FilterByGender
                handleClearFilterGender={handleClearFilterGender}
                filterGender={filterGender}
                handleFilterGender={handleFilterGender}
              />
            </section>
            <section className="hl__chip-container">
              <Chip
                label="View in map"
                variant="outlined"
                color="primary"
                icon={<FmdGoodOutlinedIcon />}
                onClick={handleOpenMapView}
              />
              <Divider orientation="vertical" flexItem />
              <Chip
                label="Sort"
                variant="outlined"
                color="primary"
                icon={<SortIcon />}
              />
              <Chip
                label="Filter"
                variant="outlined"
                color="primary"
                icon={<TuneOutlinedIcon />}
                onClick={handleOpenMobileFilter}
              />
              {/* <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer> */}
            </section>
            <section className="hl__center">
              <div className="hl__search-location">
                <div>{hostelCount} hostels found near</div>
                <div>{searchTerm}</div>
              </div>
              {hostelCount === 0 ? (
                <div>
                  <Divider variant="middle" />
                  <div className="hl__our-recom-container">
                    <h3 className="hl__our-recom-title">Our Recommendations</h3>
                  </div>
                </div>
              ) : null}

              {hostelList?.map((hostel) => {
                return (
                  <div key={hostel.id} className="hl__tile">
                    <div className="hl__tile-img-container">
                      <img
                        src={
                          hostel.imageAttachment.length > 0
                            ? hostel?.imageAttachment[0].imagePath
                            : "/images/image_not_available.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="hl__tile-main">
                      <div className="hl__fav">
                        <Favourite />
                      </div>
                      <div>
                        <div className="hl__tile-title">
                          <Tooltip title={hostel.hostelName}>
                            <h3 onClick={() => handleOpenHostelDetail(hostel)}>
                              {hostel.hostelName}
                            </h3>
                          </Tooltip>
                          <span>
                            {/* <span className="mat-icon material-symbols-outlined">
                              star_rate
                            </span>
                            <span>4.5</span> */}
                            {/* <span>{hostel.rating}</span> */}
                            <span>
                              {Math.round(hostel.distance * 10) / 10} kms away
                            </span>
                          </span>
                        </div>
                        <Tooltip
                          title={hostel.hostelAddress}
                          placement="bottom-start"
                        >
                          <p>
                            <span className="mat-icon material-symbols-outlined">
                              location_on
                            </span>
                            <span>{hostel.hostelAddress}</span>
                          </p>
                        </Tooltip>
                      </div>
                      <div className="hl__room-options">
                        <span
                          className={`gender-type ${hostel.allowedGenderType.toLowerCase()}`}
                        >
                          <span className="material-symbols-outlined">
                            {hostel.allowedGenderType === "Male"
                              ? "man"
                              : hostel.allowedGenderType === "Female"
                              ? "woman"
                              : "wc"}
                          </span>
                          {hostel.allowedGenderType}
                        </span>
                        {hostel.hostelRoomDetails.length > 0 ? (
                          hostel.hostelRoomDetails.map((d, i) =>
                            i <= 2 ? (
                              <span key={i}>{d.roomType.roomTypeName}</span>
                            ) : i === 3 ? (
                              <span key={i}>
                                + {hostel.hostelRoomDetails.length - 3} more
                              </span>
                            ) : (
                              ""
                            )
                          )
                        ) : (
                          <span className="text-secondary">not available</span>
                        )}
                      </div>
                      <div className="hl__tile-footer">
                        <div className="facilities">
                          <Facilities facilities={hostel.facilities} />
                        </div>
                        <div className="price">
                          {hostel?.hostelRoomDetails[0]?.totalAmountWithTax ? (
                            <>
                              <span className="mat-icon material-symbols-outlined">
                                currency_rupee
                              </span>
                              <span>{hostel?.hostelRoomDetails[0]?.totalAmountWithTax}</span>
                              <span>/year</span>
                            </>
                          ) : (
                            <>
                              <span className="text-secondary">
                                Price not available
                              </span>
                              <span></span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </section>
            <aside className="hl__right"></aside>
          </main>
          <React.Fragment key="bottom">
            <Drawer
              anchor="bottom"
              open={isMobileFilterOpen}
              onClose={handleCloseMobileFilter}
            >
              <div className="hl__filter-container">
                <FilterByGender
                  handleClearFilterGender={handleClearFilterGender}
                  filterGender={filterGender}
                  handleFilterGender={handleFilterGender}
                />
                <div className="hl__filter-footer">
                  <Button variant="outlined" onClick={handleCloseMobileFilter}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleCloseMobileFilter}>
                    Apply
                  </Button>
                </div>
              </div>
            </Drawer>
          </React.Fragment>
        </Container>
      )}

      <Footer />
    </div>
  );
}

const FilterByGender = (props) => {
  return (
    <div className="hl__filter-gender">
      <div className="hl__filter-gender-header">
        <h4>Filter by Gender</h4>
        <span onClick={props?.handleClearFilterGender}>Clear</span>
      </div>
      <FormControl>
        <RadioGroup
          value={props?.filterGender}
          onChange={(event) => props?.handleFilterGender(event.target.value)}
        >
          <FormControlLabel
            className="hl__filter-gender-radio"
            value="male"
            control={<Radio />}
            label="Male"
          />
          <FormControlLabel
            className="hl__filter-gender-radio"
            value="female"
            control={<Radio />}
            label="Female"
          />
          {/* <FormControlLabel
            className="hl__filter-gender-radio"
            value="both"
            control={<Radio />}
            label="Both"
          /> */}
        </RadioGroup>
      </FormControl>
    </div>
  );
};
