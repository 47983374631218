const parseJwt = (token) => {
  //console.log('token',token);
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const validateJwtExpiry = (token) => {
  const jwt = parseJwt(token);
  const exp = jwt?.exp;
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true;
};

const utility = {
  validateJwtExpiry,
};

export { utility };
