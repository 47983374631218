import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./ViewInMap.scss";
import { RoomOptions } from "../../../../components/hostel/RoomOptions/RoomOptions";
import { HostelName } from "../../../../components/hostel/HostelName/HostelName";
import { getImageGallery } from "../../../../services/httpService";

export function ViewInMap(props) {
  const { onClose, hostelData, open } = props;
  const [imageGallery, setImageGallery] = useState([]);
  const hostelId = hostelData.id;

  useEffect(() => {
    const handleGetImageGallery = async () => {
      const result = await getImageGallery({
        hostelId,
      });
      if (result) {
        setImageGallery(result.response);
      }
    };
    handleGetImageGallery();
  }, []);

  useEffect(() => {
    if (open) {
      let map;

      const initMap = async () => {
        const position = {
          lat: hostelData.latitude,
          lng: hostelData.longitude,
        };
        const { Map } = await window.google.maps.importLibrary("maps");
        const { Marker } = await window.google.maps.importLibrary("marker");

        map = await new Map(document.getElementById("viewInMap"), {
          center: position,
          zoom: 13,
          mapTypeControl: false,
          disableDefaultUI: true,
        });

        const marker = new Marker({
          map: map,
          position: position,
          icon: "/images/marker.png",
        });
      };

      initMap();
    }
  }, [open]);

  return (
    <Dialog
      className="ViewInMap"
      maxWidth="xl"
      fullWidth={true}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <div className="vim__map-container">
          <div id="viewInMap" style={{ width: "100%", height: "100%" }}></div>
          <div className="vim__hd-container">
            <div
              className="vim__img-container"
              style={{
                backgroundImage: `url("${
                  imageGallery[0]?.imageAttachment
                    ? imageGallery[0]?.imageAttachment.imagePath
                    : "/images/image_not_available.png"
                }")`,
              }}
            ></div>
            <HostelName
              name={hostelData.hostelName}
              rating={4.5}
              location={hostelData.hostelAddress}
            />
            <div className="vim__distance-from"></div>
            <RoomOptions id={hostelId} />
          </div>

          <div className="vim__close" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
