import React, { useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { LeftSection } from "../components/left-section/LeftSection";
import "./VerifyAccount.scss";
import { useLocation } from "react-router";
import environment from "../../config/environment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export function VerifyAccount() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState(state?.email);
  const [otp, setOtp] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const verifyOtp = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/verify-otp?email=${credentials.email}&otp=${credentials.otp}`;
    try {
      const response = await fetch(apiEndpoint, {
        method: "PUT",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    const verifyEmailResponse = await verifyOtp({
      email,
      otp,
    });

    if (!verifyEmailResponse) {
      setErrorMsg("Incorrect OTP");
      setOpenSnackBar(true);
    } else {
      navigate("/reset-password", {
        state: { email: email },
      });
    }
  };
  const handleOtp1 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp1(val);
    document.querySelector(".otp2 input").focus();
  };
  const handleOtp2 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp2(val);
    document.querySelector(".otp3 input").focus();
  };

  const handleOtp3 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp3(val);
    document.querySelector(".otp4 input").focus();
  };

  const handleOtp4 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setOtp4(val);
    setOtp(`${otp1}${otp2}${otp3}${val}`);
  };
  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };
  return (
    <>
      <div className="Authentication VerifyAccount">
        <div className="auth__main">
          <LeftSection imgName="room1" />
          <section className="auth__right-section">
            <div></div>
            <div className="auth__welcome-msg">
              <Typography variant="h4" color="text.secondary" align="center">
                Verify Account
              </Typography>
              <Typography variant="body2" color="text.main" align="center">
                Enter 4 digit code sent on your email
              </Typography>
            </div>
            <form
              autoComplete="off"
              onSubmit={handleVerifyOtp}
              className="auth__form-container"
            >
              <div className="auth__form auth__step2">
                <div className="auth__step2-otp">
                  <TextField
                    className="component__textbox-otp otp1"
                    onChange={(e) => handleOtp1(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    autoFocus={true}
                    value={otp1}
                  />
                  <TextField
                    className="component__textbox-otp otp2"
                    onChange={(e) => handleOtp2(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    value={otp2}
                  />
                  <TextField
                    className="component__textbox-otp otp3"
                    onChange={(e) => handleOtp3(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    value={otp3}
                  />
                  <TextField
                    className="component__textbox-otp otp4"
                    onChange={(e) => handleOtp4(e.target.value)}
                    required
                    variant="outlined"
                    type="text"
                    value={otp4}
                  />
                </div>
              </div>
              <div>
                <Button type="submit" className="auth__btn" variant="contained">
                  Verify Email
                </Button>
              </div>
            </form>
            <div></div>
            {/* <div className="auth__footer">
              <div>
                <p>Verification code not received?</p>
                <Link to="/register">Resend Verification Code</Link>
              </div>
            </div> */}
          </section>
        </div>
      </div>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
