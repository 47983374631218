import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Button from "@mui/material/Button";
import { LeftSection } from "../components/left-section/LeftSection";
import "./ResetPassword.scss";
import { TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLocation } from "react-router";
import environment from "../../config/environment";

export function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState(state?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (password == confirmPassword) {
      const resetPwd = await resetPassword({
        username: email,
        password: password,
      });
      if (!resetPwd) {
      } else {
        navigate("/login");
      }
    } else {
      alert("Password did not match with new password");
    }
  };

  const resetPassword = async (credentials) => {
    const apiEndpoint = `${environment.api}/api/v1/set-password`;
    try {
      const response = await fetch(apiEndpoint, {
        method: "PUT",
        redirect: "follow",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(credentials),
      });
      if (!response.ok) {
        // console.log("Errors", response);
        // alert(response.Errors);
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="Authentication ResetPassword">
        <div className="auth__main">
          <LeftSection imgName="room4" />
          <section className="auth__right-section">
            <div></div>
            <div className="auth__welcome-msg">
              <Typography variant="h4" color="text.secondary" align="center">
                Reset Password
              </Typography>
              <Typography variant="body2" color="text.main" align="center">
                Setup a new password
              </Typography>
            </div>
            <div className="auth__form-container">
              <div className="auth__form">
                <FormControl
                  className="component__textbox-big"
                  variant="outlined"
                >
                  <TextField
                    label="Password"
                    placeholder="New Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={passwordError}
                    className="component__textbox-big"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl
                  className="component__textbox-big"
                  variant="outlined"
                >
                  <TextField
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    error={passwordError}
                    className="component__textbox-big"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
              <Tooltip
                title="Password must be 8-16 characters with at-least 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character"
                className="register__pwd-help"
              >
                <span style={{color: "#ff6600"}}>Password rules</span>
              </Tooltip>
              <div>
                <Button
                  onClick={handleResetPassword}
                  className="auth__btn"
                  variant="contained"
                >
                  Reset Password
                </Button>
              </div>
            </div>
            <div className="auth__social"></div>
            <div className="auth__footer">
              <div>
                <p>Don't have an account?</p>
                <Link to="/register">Create an account</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
