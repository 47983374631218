import React from "react";
import "./Favourite.scss";

export default function Favourite(props) {
  const bgColor = props.bgColor ? props.bgColor : "";
  return (
    <div className="Favourite">
      <div className={`favourite__container ${bgColor}`}>
        <span className="material-symbols-outlined">favorite</span>
      </div>
    </div>
  );
}
