import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { LeftSection } from "../components/left-section/LeftSection";
import "./Login.scss";
import { TextField } from "@mui/material";
import useToken from "../../services/useToken";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { authenticateUser } from "../../services/httpService";
//import { OAuth2RedirectHandler } from "../oauth2/OAuth2RedirectHandler";

export function Login() {
  //const Oauth2ErrorMsg = OAuth2RedirectHandler.errorMsg;
  //const { Oauth2ErrorMsg } = OAuth2RedirectHandler();
  //console.log('Oauth2ErrorMsg---------',Oauth2ErrorMsg);
  const { setToken } = useToken();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState(" ");
  const [passwordHelperText, setPasswordHelperText] = useState(" ");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginBtnDisabled, setLoginBtnDisabled] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [returnUrl, setReturnUrl] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const { handleGoogle, loading, error } = useFetch("http://localhost:3000");

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setAuthError(false);
  };

  const handleCloseforOauthError = () => {
    localStorage.removeItem("errorMsg");
    setAuthError(false);
  };

  const handleLogin = async (event) => {
    setLoginBtnDisabled(true);
    event.preventDefault();
    const token = await authenticateUser({
      username,
      password,
    });
    if (!token) {
      setAuthError(true);
      setLoginErrorMsg("Login failed. Please check your email/password.");
      //setLoginErrorMsg("Please enter a valid password. It must be 8-16 characters long, with at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.");
      setLoginBtnDisabled(false);
    } else {
      setToken(token);
      returnUrl ? navigate(`/${returnUrl}`) : navigate("/");
    }
  };

const Oauth2ErrorMsg = localStorage.getItem("errorMsg")
console.log('Oauth2ErrorMsg-----',Oauth2ErrorMsg);
//localStorage.clear();

  // useEffect(() => {
  //   setReturnUrl(location.search?.slice(11));

  //   if (window.google) {
  //     window.google.accounts.id.initialize({
  //       client_id:
  //         "1092535742584-i8gt85jur57sdn08nqm074it4fubntst.apps.googleusercontent.com",
  //       callback: handleGoogle,
  //     });

  //     window.google.accounts.id.renderButton(
  //       document.getElementById("googleLogin"),
  //       {
  //         type: "standard",
  //         text: "signin_with",
  //         shape: "pill",
  //       }
  //     );
  //   }
  // }, [handleGoogle]);

  return (
    
    <>
      <div className="Authentication Login">
        <div className="auth__main">
          <LeftSection imgName="room1" />
          <section className="auth__right-section">
            <div></div>
            <div className="auth__welcome-msg">
              <Typography variant="h4" color="text.secondary" align="center">
                Welcome
              </Typography>
              <Typography variant="body2" color="text.main" align="center">
                Login to your account
              </Typography>
            </div>
            <form autoComplete="off" onSubmit={handleLogin}>
              <div className="auth__form-container">
                <div className="auth__form">
                  <TextField
                    label="Email Id"
                    placeholder="user@email.com"
                    className="component__textbox-big"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    variant="outlined"
                    type="email"
                    value={username}
                    error={emailError}
                    helperText={emailHelperText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Password"
                    placeholder="**********"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={passwordError}
                    className="component__textbox-big"
                    helperText={passwordHelperText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffOutlinedIcon />
                            ) : (
                              <VisibilityOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Remember Me"
                    />
                  </FormGroup>
                  <Button
                    type="submit"
                    className="auth__btn"
                    variant="contained"
                    disabled={isLoginBtnDisabled}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </form>
            <div className="auth__social">
              <div className="auth__social-or">
                <div></div>
                <div>or</div>
              </div>
              <div className="auth__social-google" >
                <img alt="google logo" src="/images/google-icon.png" />
                <span><a
                href="https://secureprod.myhostelroom.com/oauth2/authorize/google?redirect_uri=https://www.myhostelroom.com/oauth2/redirect"
              >Continue with Google</a></span>
              </div>
              <div id="googleLogin"></div>
               {/*<a
                target="_blank"
                href="http://ec2-13-232-240-70.ap-south-1.compute.amazonaws.com:5000/oauth2/authorize/google?redirect_uri=http://localhost:3000/#/login"
              >
                Google
              </a>
              <div
                id="g_id_onload"
                data-client_id="YOUR_GOOGLE_CLIENT_ID"
                data-login_uri="https://your.domain/your_login_endpoint"
                data-auto_prompt="false"
              ></div>
              <div
                className="g_id_signin"
                data-type="standard"
                data-size="large"
                data-theme="outline"
                data-text="sign_in_with"
                data-shape="rectangular"
                data-logo_alignment="left"
              ></div> */}
            </div>
            <div className="auth__footer">
              <div>
                <p>Don't have an account?</p>
                <Link to="/register">Create an account</Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Snackbar
        open={authError}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {loginErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Oauth2ErrorMsg!="" && Oauth2ErrorMsg!=null && Oauth2ErrorMsg!=undefined}
        autoHideDuration={3000}
        onClose={handleCloseforOauthError}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleCloseforOauthError} severity="error" sx={{ width: "100%" }}>
         {Oauth2ErrorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGoogle = async (response) => {
    console.log(response);
  };
  return { loading, error, handleGoogle };
};
