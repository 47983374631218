import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./PrivacyPolicy.scss";

export function PrivacyPolicy() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="PrivacyPolicy">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="au__top-toolbar">
            <BackButton />
          </div> */}
          <main className="au__main-container">
            <section className="au__left">
              <h2 style={{ color: "#ff6600" }}>Privacy Policy</h2>
              <p>
                We at Kyozon Hostels are committed to protecting the privacy and
                confidentiality of our users' personal information. This Privacy
                Policy outlines how we collect, use, disclose, and protect the
                information you provide to us when using our services or
                accessing our website or portal (collectively referred to as the
                "Portal"). By using the Portal, you consent to the practices
                described in this Privacy Policy.
              </p>
              <h4>1. Information We Collect</h4>
              <ul>
                <li>
                  <h4>1.1 Personal Information:</h4>
                  <ul>
                    <li>
                      We may collect personal information such as your name,
                      email address, contact number, and other information that
                      you voluntarily provide to us when creating an account or
                      using our services. We may also collect additional
                      personal information as required for specific services or
                      transactions.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>1.2 Non-Personal Information:</h4>
                  <ul>
                    <li>
                      We may collect non-personal information, such as your IP
                      address, device information, and usage data, which is
                      automatically collected when you access and use the
                      Portal. This information helps us analyze trends,
                      administer the Portal, track user activities, and gather
                      demographic information for overall improvement of our
                      services.
                    </li>
                  </ul>
                </li>
              </ul>
              <h4>2. Use of Information</h4>
              <ul>
                <li>
                  <h4>2.1 Personal Information:</h4>
                  <h4>
                    We may use the personal information you provide to us for
                    the following purposes:
                  </h4>
                  <ul>
                    <li>
                      To process and fulfill your requests, inquiries, and
                      transactions.
                    </li>
                    <li>
                      To personalize your experience and provide tailored
                      content and services.
                    </li>
                    <li>
                      To communicate with you, including responding to your
                      inquiries and providing updates on our services.
                    </li>
                    <li>
                      To send you promotional materials and marketing
                      communications, subject to your preferences and applicable
                      laws.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>2.2 Non-Personal Information:</h4>
                  <ul>
                    <li>
                      We may use non-personal information for statistical
                      analysis, system administration, improving the Portal, and
                      enhancing user experiences. This information may also be
                      aggregated and used in an anonymous manner for various
                      purposes.
                    </li>
                  </ul>
                </li>
              </ul>
              <h4>Our Values </h4>
              <ul>
                <li>
                  <h4>3.1 Service Providers:</h4>
                  <ul>
                    <li>
                      We may engage trusted third-party service providers who
                      assist us in operating our business and providing services
                      to you. These service providers are bound by
                      confidentiality obligations and are only authorized to use
                      your information as necessary to perform their services
                      for us.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>3.2 Legal Requirements:</h4>
                  <ul>
                    <li>
                      We may disclose your information if required to do so by
                      law or in response to valid legal requests, such as
                      subpoenas, court orders, or government regulations. We may
                      also disclose your information to protect our rights,
                      safety, or property, or the rights, safety, or property of
                      others.
                    </li>
                  </ul>
                </li>
              </ul>
              <h4>4. Data Security</h4>
              <ul>
                <li>
                  We implement reasonable security measures to protect your
                  personal information from unauthorized access, disclosure,
                  alteration, or destruction. However, please be aware that no
                  method of transmission over the internet or electronic storage
                  is 100% secure, and we cannot guarantee absolute security.
                </li>
              </ul>
              <h4>5. Third-Party Links</h4>
              <ul>
                <li>
                  The Portal may contain links to third-party websites or
                  services that are not controlled or operated by us. This
                  Privacy Policy does not apply to such third-party websites or
                  services, and we are not responsible for their privacy
                  practices. We encourage you to review the privacy policies of
                  those third parties before providing any personal information.
                </li>
              </ul>
              <h4>6. Children's Privacy</h4>
              <ul>
                <li>
                  The Portal is not intended for individuals under the age of
                  18. We do not knowingly collect personal information from
                  children. If you are a parent or guardian and believe that
                  your child has provided us with personal information, please
                  contact us, and we will take steps to delete such information
                  from our systems.
                </li>
              </ul>
              <h4>7. Changes to this Privacy Policy</h4>
              <ul>
                <li>
                  We reserve the right to modify or update this Privacy Policy
                  at any time. Any changes will be effective upon posting the
                  revised policy on the Portal. We encourage you to review this
                  Privacy Policy periodically to stay informed about our data
                  practices.
                </li>
              </ul>
              <p>
                By using the Portal, you acknowledge that you have read and
                understood this Privacy Policy and agree to the collection, use,
                and disclosure of your information as described herein.
              </p>
            </section>
            <aside className="au__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
