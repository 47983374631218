import React, { useState, useEffect } from "react";
import { getHostelRules } from "../../../../services/httpService";
import "./HostelRules.scss";

export function HostelRules(props) {
  const [hostelRules, setHostelRules] = useState([]);
  const [showRules, setShowRules] = useState(false);

  const handleShowRules = () => {
    showRules ? setShowRules(false) : setShowRules(true);
  };

  const handleGetHostelRules = () => {
    getHostelRules({ hostelId: props?.id })
      .then((result) => {
        setHostelRules(result.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleGetHostelRules();
  }, []);

  return (
    <div className="HostelRules">
      <header>Hostel Rules</header>
      <ul className={showRules ? "show" : ""}>
        {hostelRules?.map((d, i) => {
          return <li key={i}>{d?.rule?.ruleDescription}</li>;
        })}
      </ul>
      <button onClick={handleShowRules}>
        View {showRules ? "Less" : "More"}
      </button>
    </div>
  );
}
