import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("access_token");
    return tokenString;
  };

  const getUserId = () => {
    const userIdString = localStorage.getItem("userId");
    return userIdString;
  };

  const [token, setToken] = useState(getToken());
  const [userId, setUserId] = useState(getUserId());

  const saveToken = (userToken) => {
    localStorage.setItem(
      "access_token",
      JSON.stringify(userToken?.access_token)
    );
    localStorage.setItem("userId", JSON.stringify(parseInt(userToken?.id)));
    //console.log('token',userToken.access_token);
    setToken(userToken.access_token);
    setUserId(parseInt(userToken.id));
  };

  return {
    setToken: saveToken,
    token,
    userId,
  };
}
