import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import "./ManageBookings.scss";
import {
  getImageGallery,
  getUserBookings,
  payNextEmi,
  downloadReceiptVoucher,
} from "../../services/httpService";
import useToken from "../../services/useToken";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CircularProgress, FormLabel } from "@mui/material";
import { Tooltip } from '@mui/material';

const HostelImage = (props) => {
  const [imageLink, setImageLink] = useState("");
  const getHostelImage = async (props) => {
    const hostelImage = await getImageGallery({ hostelId: props?.hostelId });
    if (hostelImage && hostelImage.status === 200) {
      setImageLink(hostelImage.response[0]?.imageAttachment.imagePath);
    }
  };

  getHostelImage(props);

  console.log(imageLink);
  return <img src={imageLink} alt="hostel-room" />;
};

export function ManageBookings() {
  const { token, userId } = useToken();
  const [loading, setLoading] = useState(true);
  const [bookingDetail, setBookingDetail] = useState([]);
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleGetUserBookings = async (data) => {
      const userBooking = await getUserBookings({ token, userId });
      if (!userBooking || userBooking?.status !== 200) {
        console.log(userBooking);
      } else {
        setLoading(false);
        console.log(userBooking?.response);
        setBookingDetail(userBooking?.response);
      }
    };
    handleGetUserBookings();
  }, []);

  const handlePayNow = async (paymentHistoryId) => {
    const payEmi = await payNextEmi({
      paymentHistoryId,
      token,
    });
    if (!payEmi) {
      navigate("/booking-failed");
    } else {
      const paymentURL = payEmi;
      openWindowReload(paymentURL);
    }
  };

  const handleReceiptVoucher = async (paymentHistoryId) => {
    const rv = await downloadReceiptVoucher({
      paymentHistoryId,
      token,
    });
    if (rv !== "") {
      //console.log('rv', rv);
      const apiResByteArr = rv.response;
      //console.log('apiResByteArr', apiResByteArr);
      const pdf = {
        file: `data:application/pdf;base64,${apiResByteArr}`,
        file_name: `receipt_voucher_${paymentHistoryId}`,
      };
      downloadPDF(pdf);
    }
  };

  function downloadPDF(pdf) {
    const pdfLink = `${pdf.file}`;
    const anchorElement = document.createElement("a");
    const fileName = `${pdf.file_name}.pdf`;
    anchorElement.href = pdfLink;
    anchorElement.download = fileName;
    anchorElement.click();
  }
  function openWindowReload(link) {
    var href = link;
    window.location.href = href;
  }

  return (
    <div className="ManageBookings">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <main className="mb__main-container">
            <section className="mb__left">
              <h2>Manage Bookings</h2>
              {bookingDetail?.map((b, i) => (
                <div className="mb__tile" key={i}>
                  <div className="mb__tile-header">
                    <div>
                      <HostelImage hostelId={b?.hostelId} />
                    </div>
                    <div>
                      <h3>{b?.hostelName}</h3>
                      <p>{b?.hostelAddress}</p>
                      <div>{b?.roomTypeName}</div>
                    </div>
                  </div>
                  <div className="mb__tile-main">
                    <div>
                      <span
                        className={`mat-icon material-symbols-outlined ${b?.bookingStatus}`}
                      >
                        {b?.bookingStatus === "Tentavive"
                          ? "new_releases"
                          : "block"}
                      </span>
                      <div>
                        <label>Booking Status</label>
                        <p>{b?.bookingStatus}</p>
                      </div>
                    </div>

                    <div>
                      <span className="mat-icon material-symbols-outlined">
                        tag
                      </span>
                      <div>
                        <label>Booking Number</label>
                        <p>{b?.bookingNo}</p>
                      </div>
                    </div>

                    <div>
                      <span className="mat-icon material-symbols-outlined">
                        calendar_month
                      </span>
                      <div>
                        <label>Booking Date</label>
                        <p>{b?.bookingDateTime}</p>
                      </div>
                    </div>

                    <div>
                      <span className="mat-icon material-symbols-outlined">
                        calendar_month
                      </span>
                      <div>
                        <label>Stay Duration</label>
                        <p>
                          {b?.startDate}
                          {"  -  "}
                          {b?.endDate}
                        </p>
                      </div>
                    </div>

                    <div>
                      <span className="mat-icon material-symbols-outlined">
                        currency_rupee
                      </span>
                      <div>
                        <label>Amount Paid</label>
                        <p>{b?.amount}</p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div hidden={b?.bookingStatus === "Cancelled" ? "true" : ""}>
                    <b>Payment Details:</b>
                    <TableContainer
                      component={Paper}
                      className="bd__payment-summary-pst"
                    >
                      <Table sx={{ minWidth: 540 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell width="100">Due Date</TableCell>
                            <TableCell width="150" align="right">
                              Rent
                            </TableCell>
                            <TableCell width="130" align="right">
                              Transaction Id
                            </TableCell>
                            <TableCell width="160" align="right">
                              Security Deposit
                            </TableCell>

                            <TableCell width="100" align="right">
                              Paid On
                            </TableCell>
                            {/* <TableCell width="120" align="right">
                      Tax
                    </TableCell> */}
                            <TableCell width="300" align="right">
                              Total
                            </TableCell>
                            <TableCell width="100" align="right">
                              Status
                            </TableCell>

                            <TableCell width="250" align="right">
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {b.paymentEmiDetails.map((row, i) => (
                            <TableRow
                              key={row.paymentDueDate}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.paymentDueDate.slice(0, 10)}
                              </TableCell>
                              <TableCell align="right">
                                &#8377; {row.rent}
                              </TableCell>
                              <TableCell align="right">
                                {row?.payment?.transactionId}
                              </TableCell>
                              <TableCell align="right">
                                &#8377; {row.securityDeposit}
                              </TableCell>
                              {/* <TableCell align="right">
                        &#8377; {getTax(row.tax)}
                      </TableCell> */}
                              <TableCell align="right">
                                {row.paidDate}
                              </TableCell>
                              <TableCell align="right">
                                <strong>
                                  &#8377;
                                  {row.grandTotal}
                                </strong><br />
                                {row.penaltyAmount ? <Tooltip title={"₹"+ row.penaltyAmount+ " Additional charges"} arrow placement="right"><span style={{color: "red"}}>Additional charges inclusive</span></Tooltip> : ""}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  color:
                                    row.paymentStatus === "PAID"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {row.paymentStatus}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  color:
                                    row.paymentStatus === "PAID"
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {row.paymentStatus === "PAID" ? (
                                  ("PAID",
                                  (
                                    <Button
                                      onClick={(e) =>
                                        handleReceiptVoucher(row.id)
                                      }
                                    >
                                      <span class="material-symbols-outlined">
                                        download
                                      </span>
                                    </Button>
                                  ))
                                ) : (
                                  <Button
                                    variant="contained"
                                    // disabled= {}
                                    onClick={(e) => {
                                      handlePayNow(row.id);
                                    }}
                                  >
                                    Pay Now
                                  </Button>
                                )}

                                {/* <Button
                                  onClick={(e) => handleReceiptVoucher(row.id)}
                                >
                                  <span class="material-symbols-outlined">
                                    download
                                  </span>
                                </Button> */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ))}
            </section>
            <aside className="mb__right"></aside>
          </main>
        </Container>
      )}
      <Grid sx={{ mt: 10 }}>
        <Footer />
      </Grid>
    </div>
  );
}
