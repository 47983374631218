import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./UserNavigation.scss";
import { Tooltip } from "@mui/material";

export default function UserNavigation(props) {
  const links = [
    {
      name: "Update Profile",
      link: "/update-profile",
      icon: "photo_camera_front",
    },
    {
      name: "Manage Bookings",
      link: "/manage-bookings",
      icon: "manage_history",
    },
    {
      name: "Complaints",
      link: "/complaints-and-feedback",
      icon: "feedback",
    },
    // {
    //   name: "Payment Methods",
    //   link: "/payment-methods",
    //   icon: "credit_card",
    // },
    // {
    //   name: "Notification Settings",
    //   link: "/notification-settings",
    //   icon: "notifications",
    // },
    // {
    //   name: "Security Settings",
    //   link: "/security-settings",
    //   icon: "key",
    // },
    // {
    //   name: "Help & Support",
    //   link: "/support",
    //   icon: "contact_support",
    // },
    {
      name: "Logout",
      link: "/logout",
      icon: "logout",
    },
  ];

  return (
    <nav className="UserNavigation">
      <div className="un__container">
        <div className="un__logo">
          <Link to="/">
            <img alt="logo" src="/logo.png" />
          </Link>
        </div>
        <div className="un__user-container">
          <div className="un__user-pic">
          {(() => {
                  if (props.data.profileUrl != undefined || props.data.profileUrl != null) {
                    return (
                      <img src={props.data.profileUrl} alt="Avatar" 
                      style={{borderRadius: "20px"}} 
                     />
                    )
                  }
                  else {
                    return (
                      <span className="material-symbols-outlined">person</span>
                    )
                  }
                })()}
          </div>
          <div className="un__user-detail">
            <h3>{props.data.fullName}</h3>
            <Tooltip title={props.data.email}>
              <p>{props.data.email}</p>
            </Tooltip>
          </div>
        </div>
        <nav className="un__nav">
          <ul>
            {links.map((d, i) => {
              return (
                <li key={i}>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "none")}
                    to={d.link}
                  >
                    <span className="mat-icon material-symbols-outlined">
                      {d.icon}
                    </span>
                    <span>{d.name}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </nav>
  );
}
