import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";
import "./BookingSuccess.scss";

export function BookingSuccess() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="BookingSuccess">
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          <main className="bs__main-container">
            <span className="mat-icon material-symbols-outlined">task_alt</span>
            <h2>Payment Successful</h2>
            <p>
              We have captured your booking request. Our representative will get
              in touch soon for further processing.{" "}
            </p>
            <p>
              Or, you can also contact us as below:
              <br /> <br />
              Call us @: <strong>8007005599 / 8888165599</strong>
              <br />
              Email us @: <strong>sales@myhostelroom.com </strong>
            </p>
            <Link to="/manage-bookings" className="bs__cta-btn">
              View My Bookings
            </Link>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
