import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import CircularProgress from "@mui/material/CircularProgress";
import "./Logout.scss";

export function Logout() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      handleLogout();
    }, 2000);
  }, []);

  return (
    <div className="Logout">
      <Header />
      <Navbar />

      <Container>
        <div className="logout__main">
          <CircularProgress />
          <h3>Logging out securely...</h3>
        </div>
      </Container>

      <Footer />
    </div>
  );
}
