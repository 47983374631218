import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import "./BookingDetail.scss";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, CircularProgress, FormLabel, TextField } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import useToken from "../../services/useToken";
import { RoomOptions } from "./components/RoomOptions/RoomOptions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  getHostelData,
  getImageGallery,
  payAndBook,
  getEmiSummary,
  getUserDetail,
  getPayOption,
  onPaymentSuccess,
  onPaymentFailure,
  getTemsConditions,
  getRoomDetails,
  verifyMobileOtp,
  getMobileOtp,
} from "../../services/httpService";
import { BookingSummary } from "./components/BookingSummary/BookingSummary";
import { AmountToPayNow } from "./components/AmountToPayNow/AmountToPayNow";
import { PaymentSummary } from "./components/PaymentSummary/PaymentSummary";
import environment from "../../config/environment";

export function BookingDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, userId } = useToken();
  const hostelId = location.state?.hostelId;
  const [hostelData, setHostelData] = useState();
  const [hostelImage, setHostelImage] = useState([]);
  const [hostelRoomId, setHostelRoomId] = useState(
    location.state?.hostelRoomId
  );
  const [roomTypeName, setRoomTypeName] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState();
  const [stayDuration, setStayDuration] = useState("12");
  const [payDuration, setPayDuration] = useState("2");
  const [payOption, setPayOption] = useState([]);
  const [payOptionId, setPayOptionId] = useState("2");
  const [openPaymentGateway, setOpenPaymentGateway] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [loading, setLoading] = useState(false);
  const [bookingInProgress, setBookingInProgress] = useState(true);
  const [payAndBookDisabled, setPayAndBookDisabled] = useState(true);
  const [bookError, setBookError] = useState(false);
  const [openTermsCondition, setOpenTermsCondition] = useState(false);
  const [checkedTermsCondition, setCheckedTermsCondition] = useState(false);
  const [checkedRentAgreement, setCheckedRentAgreement] = useState(false);
  const [openRentAgreement, setOpenRentAgreement] = useState(false);
  const [tncList, setTncList] = useState([]);
  const [ismobileVerified, setIsMobileVerified] = useState(false);
  const [mobileVerifiedError, setMobileVerifiedError] = useState(false);
  const todayDate = new Date().toISOString().slice(0, 10);
  const [moveInDate, setMoveInDate] = useState("");
  const [openOtpScreen, setOpenOtpScreen] = useState(false);
  const [mobileotp1, setMobileOtp1] = useState("");
  const [mobileotp2, setMobileOtp2] = useState("");
  const [mobileotp3, setMobileOtp3] = useState("");
  const [mobileotp4, setMobileOtp4] = useState("");
  const [mobileotp5, setMobileOtp5] = useState("");
  const [mobileotp6, setMobileOtp6] = useState("");
  const [mobileotp, setMobileOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [verifyMobileNumberSuccess, setVerifyMobileNumberSuccess] =
    useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isGetOtpDisabled, setGetOtpDisabled] = useState(false);

  const handleVerifyMobileOtp = async (event) => {
    event.preventDefault();
    const verifyMobileResponse = await verifyMobileOtp({
      userId,
      mobileotp,
      token,
    });

    
    if (!verifyMobileResponse) {
      setErrorMsg("Incorrect OTP");
      setOpenSnackBar(true);
    } else {
      setOpenOtpScreen(false);
      setVerifyMobileNumberSuccess(true);
      handleGetUserDetail();
      //document.getElementById("paynbookbtn").click();
    }
  };

  const handleClose = () => {
    setVerifyMobileNumberSuccess(false);
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar(false);
  };
  const handleCloseVerifyMobile = () => {
    setOpenOtpScreen(false);
  };
  const handleMobileOtp1 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp1(val);
    document.querySelector(".mobileotp2 input").focus();
  };

  const handleMobileOtp2 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp2(val);
    document.querySelector(".mobileotp3 input").focus();
  };

  const handleMobileOtp3 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp3(val);
    document.querySelector(".mobileotp4 input").focus();
  };

  const handleMobileOtp4 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp4(val);
    document.querySelector(".mobileotp5 input").focus();
  };

  const handleMobileOtp5 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp5(val);
    document.querySelector(".mobileotp6 input").focus();
  };

  const handleMobileOtp6 = (val) => {
    if (val.length > 1 || val.match(/[^0-9]/)) {
      return;
    }
    setMobileOtp6(val);
    setMobileOtp(
      `${mobileotp1}${mobileotp2}${mobileotp3}${mobileotp4}${mobileotp5}${val}`
    );
  };

  const handleOnPaymentSuccess = async (data) => {
    setBookingInProgress(true);
    const paymentSuccess = await onPaymentSuccess({
      body: data,
      token,
    });
    if (!paymentSuccess) {
      console.log("Error", paymentSuccess);
      navigate("/booking-failed");
    } else {
      console.log("Success", paymentSuccess);
      navigate("/booking-success");
    }
  };

  const handleOnPaymentFailure = async (data) => {
    setBookingInProgress(true);
    const paymentFailed = await onPaymentFailure({ body: data, token });
    if (!paymentFailed) {
      console.log("Success", paymentFailed);
      navigate("/booking-failed");
    } else {
      console.log("Failed", paymentFailed);
      navigate("/booking-failed");
    }
  };

  const dropinConfig = {
    components: ["order-details", "card", "netbanking", "app", "upi"],
    onSuccess: (data) => {
      handleOnPaymentSuccess(data);
    },
    onFailure: (data) => {
      handleOnPaymentFailure(data);
    },
    style: {
      //to be replaced by the desired values
      backgroundColor: "#ffffff",
      color: "#ff6600",
      fontFamily: "Lato",
      fontSize: "14px",
      errorColor: "#ff0000",
      theme: "light",
    },
  };

  const handleGetHostelData = async () => {
    const hostel = await getHostelData({ hostelId });
    if (!hostel) {
    } else {
      setHostelData(hostel?.response);
    }

    const hostelImg = await getImageGallery({ hostelId });
    if (!hostelImg) {
    } else {
      setHostelImage(hostelImg?.response);
    }
  };

  const handleGetUserDetail = async () => {
    const user = await getUserDetail({
      userId,
      token,
    });
    if (!user) {
      setUserLoggedIn(false);
    } else {
      setIsMobileVerified(user?.response?.isPhoneNumberVerified);
      setUserData(user?.response);
    }
  };

  const handlePayAndBook = async (event) => {
    if (ismobileVerified) {
      setPayAndBookDisabled(true);
      // setOpenPaymentGateway(true);
      setBookingInProgress(true);
      const book = await payAndBook({
        body: {
          userId: parseInt(userId),
          hostelId: parseInt(hostelId),
          hostelRoomDetailsId: parseInt(hostelRoomId),
          startDate: moveInDate.split("-").reverse().join("/"),
          stayDuration: parseInt(stayDuration),
          couponId: null,
          isAppliedCoupon: false,
          bookingStatus: "InProgress",
          payOptionId: parseInt(payOptionId),
          remarks: "",
        },
        token,
      });
      if (!book) {
        setBookingInProgress(false);
        navigate("/booking-exist");
      } else {
        setBookingInProgress(false);
        const paymentURL = book?.response.paymentEmiDetails[0].payment.payUrl;
        openWindowReload(paymentURL);
      }
    } else {
      setMobileVerifiedError(true);
    }
  };

  function openWindowReload(link) {
    var href = link;
    window.open(
      href,
      "_blank",
      "toolbar=1, scrollbars=1, resizable=1, width=" + 1015 + ", height=" + 800
    );
    // window.location.href = href;
  }
  const handleStayDuration = async (event) => {
    const sd = event.target.value;
    setStayDuration(sd);
    setPayDuration(sd);
    handleGetPayOption(sd);
    const payment = await getEmiSummary({
      hostelRoomId,
      stayDuration: event.target.value,
      payDuration: event.target.value,
      token: token ? token : "",
    });
    if (!payment) {
    } else {
      setPaymentData(payment.response);
    }
  };

  const handlePayDuration = async (event) => {
    setPayOptionId(event.target.value);
    const payment = await getEmiSummary({
      hostelRoomId,
      stayDuration,
      payDuration: event.target.value,
      token: token ? token : "",
      startDate: moveInDate,
    });
    if (!payment) {
    } else {
      setPaymentData(payment.response);
    }
  };

  const handleGetPayOption = async (val) => {
    const po = await getPayOption(val);
    if (po) {
      setPayOption(po?.response);
    }
  };

  const handleStayDurationChange = async (event) => {
    const sd = event.target.value;
    setStayDuration(sd);
    //setPayDuration(sd);
    handleGetPayOption(sd);
    const payment = await getEmiSummary({
      hostelRoomId,
      stayDuration: event.target.value,
      payDuration: payOptionId,
      token: token ? token : "",
      startDate: moveInDate,
    });
    if (!payment) {
    } else {
      setPaymentData(payment.response);
    }
  };

  const handleSelectRoom = async (val) => {
    setHostelRoomId(val);
    const payment = await getEmiSummary({
      hostelRoomId: val,
      stayDuration,
      payDuration,
      token: token ? token : "",
      startDate: moveInDate,
    });
    if (payment) {
      setPaymentData(payment.response);
    }
  };

  const handleSelectRoomName = async (val) => {
    setRoomTypeName(val);
  };

  const handleMoveInDate = (event) => {
    const d = event.target.value;
    setMoveInDate(d);
  };

  const handleIAgreeChange = (event) => {
    if (event.target.checked) {
      setCheckedTermsCondition(true);
      if (userLoggedIn && checkedRentAgreement) setPayAndBookDisabled(false);
    } else {
      setCheckedTermsCondition(false);
      setPayAndBookDisabled(true);
    }
  };

  const handleRentAgreementChange = (e) => {
    if (e.target.checked) {
      setCheckedRentAgreement(true);
      if (userLoggedIn && checkedTermsCondition) setPayAndBookDisabled(false);
    } else {
      setCheckedRentAgreement(false);
      setPayAndBookDisabled(true);
    }
  };

  const handleCloseSnackBar = () => {
    setBookError(false);
    setMobileVerifiedError(false);
  };

  const handleGetMobileOtp = async (event) => {
    const mobile = userData?.mobileNo;
    event.preventDefault();
    const response = await getMobileOtp({
      token,
      mobile,
    });
    if (!response) {
      setOpenSnackBar(true);
      setGetOtpDisabled(false);
    } else {
      setOpenOtpScreen(true);
    }
  };

  const handleTermsConditions = async () => {
    setOpenTermsCondition(true);
    const tnc = await getTemsConditions();
    if (!tnc) {
    } else {
      setTncList(tnc?.response);
    }
  };

  const handleRentAgreement = async () => {
    setOpenRentAgreement(true);
    const tnc = await getTemsConditions();
    if (!tnc) {
    } else {
      setTncList(tnc?.response);
    }
  };

  const handleCloseTermsCondition = () => {
    setOpenTermsCondition(false);
  };

  const handleCloseRentAgreement = () => {
    setOpenRentAgreement(false);
  };

  const getGrandTotal = (paymentData) => {
    const taxDetail = paymentData?.totalTaxDetails;
    const totalRent = paymentData?.totalRent;
    let totalTax = 0;
    taxDetail?.map((d) => {
      totalTax += parseInt(d?.taxAmount);
    });
    const gt = parseInt(totalTax) + parseInt(totalRent);
    return gt;
  };

  useEffect(() => {
    setLoading(true);
    if (!hostelId) {
      navigate("/");
    } else {
      handleGetHostelData();
      setPayAndBookDisabled(true);
      handleGetPayOption(stayDuration);
      setMoveInDate(todayDate);
      const payment = async () => {
        const p = await getEmiSummary({
          hostelRoomId,
          stayDuration,
          payDuration,
          token: token ? token : "",
          startDate: moveInDate,
        });
        if (!p) {
        } else {
          setPaymentData(p.response);
          setLoading(false);
        }
      };
      payment();
      if (token) {
        setUserLoggedIn(true);
        handleGetUserDetail();
      } else {
        setUserLoggedIn(false);
      }
    }
  }, []);
  const goToUpdateProfile = () => {
    navigate("/update-profile");
  };

  return (
    <>
      <div className="BookingDetail">
        <Header />
        <Navbar />
        {loading ? (
          <Loader />
        ) : (
          <Container sx={{ padding: { xs: 0, sm: "0 24px" } }}>
            <h3 className="page-title">Booking Details</h3>
            <main className="bd__main-container">
              <section className="bd__left">
                <div className="bd__accordion-container">
                  <Accordion expanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Room Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <div className="bd__rd bd__rd-0">
                          <div className="bd__rd-0-img">
                            <img
                              src={hostelImage[0]?.imageAttachment?.imagePath}
                              alt="room-pic"
                            />
                          </div>
                          <div>
                            <h3>{hostelData?.hostelName}</h3>
                            <p>{hostelData?.hostelAddress}</p>
                          </div>
                        </div>
                        <div className="bd__rd bd__rd-1">
                          <div className="booking-step">1</div>
                          <div>
                            <h4>Select a room type</h4>
                            <RoomOptions
                              id={hostelId}
                              selectRoom={handleSelectRoom}
                              selectRoomName={handleSelectRoomName}
                            />
                            <h4>Note: All prices are inclusive of GST. </h4>
                          </div>
                        </div>

                        <div className="bd__rd bd__rd-2">
                          <div className="booking-step">2</div>
                          <div>
                            <h4>When would you like to move in?</h4>
                            <input
                              required
                              type="date"
                              min={todayDate}
                              value={moveInDate}
                              onChange={handleMoveInDate}
                            />
                          </div>
                        </div>

                        {/* <div className="bd__rd bd__rd-3">
                          <div className="booking-step">3</div>
                          <div>
                            <h4>How long you would like to stay?</h4>
                            <FormControl>
                              <RadioGroup
                                row
                                value={stayDuration}
                                onChange={handleStayDuration}
                                name="stay-duration-buttons-group"
                              >
                                <FormControlLabel
                                  value="3"
                                  control={<Radio />}
                                  label="3 months"
                                />
                                <FormControlLabel
                                  value="6"
                                  control={<Radio />}
                                  label="6 months"
                                />
                                <FormControlLabel
                                  value="9"
                                  control={<Radio />}
                                  label="9 months"
                                />
                                <FormControlLabel
                                  value="12"
                                  control={<Radio />}
                                  label="12 months"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div> */}

                        <div className="bd__rd bd__rd-3">
                          <div className="booking-step">3</div>
                          <div>
                            <h4>How long you would like to stay?</h4>
                            <FormControl>
                              <Select
                                sx={{ width: "100%" }}
                                id="stayDuration"
                                name="stayDuration"
                                defaultValue={"12"}
                                onChange={handleStayDurationChange}
                              >
                                <MenuItem value={"3"}>3</MenuItem>
                                <MenuItem value={"4"}>4</MenuItem>
                                <MenuItem value={"6"}>6</MenuItem>
                                <MenuItem value={"12"}>12</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="bd__rd bd__rd-4">
                          <div className="booking-step">4</div>
                          <div>
                            <h4>How would you like to pay?</h4>
                            <FormControl>
                              <RadioGroup
                                row
                                value={payOptionId}
                                onChange={handlePayDuration}
                                name="pay-duration-buttons-group"
                              >
                                {payOption?.map((d, i) => (
                                  <FormControlLabel
                                    key={i}
                                    value={d.id}
                                    control={<Radio />}
                                    label={d.name}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <Button
                          onClick={openUserPanel("panel2")}
                          variant="outlined"
                        >
                          Next
                        </Button>
                      </div> */}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="bd__accordion-student" expanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="panel2bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        User Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {userLoggedIn ? (
                        <div className="bd__accordion-student-main">
                          <div>
                            <label>Full Name</label>
                            <p>{userData?.fullName}</p>
                          </div>

                          <div>
                            <label>Mobile Number</label>
                            <p>{userData?.mobileNo}</p>
                          </div>

                          <div>
                            <label>Email Id</label>
                            <p>{userData?.username}</p>
                          </div>
                        </div>
                      ) : (
                        <p style={{ textAlign: "center" }}>
                          You need to{" "}
                          <Link to="/login?returnUrl=booking-detail">
                            Login
                          </Link>{" "}
                          to book the room
                        </p>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </section>
              <section className="bd__right">
                <BookingSummary
                  roomTypeName={roomTypeName}
                  moveInDate={moveInDate}
                  stayDuration={stayDuration}
                />

                <PaymentSummary
                  hostelId={hostelRoomId}
                  stayDuration={stayDuration}
                  payDuration={payOptionId}
                  data={paymentData}
                  moveInDate={moveInDate}
                />

                <AmountToPayNow data={paymentData} />
                
                <div className="bd__tnc">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={handleIAgreeChange}
                    />
                  </FormGroup>
                  <FormLabel>
                    I agree to all the{" "}
                    <span onClick={handleTermsConditions}>
                      Terms &amp; Conditions
                    </span>
                    &nbsp;of my hostel room including cancellation, payment and
                    extra charges.
                  </FormLabel>
                  <Dialog
                    fullWidth={true}
                    open={openTermsCondition}
                    onClose={handleCloseTermsCondition}
                    maxWidth="lg"
                  >
                    <DialogTitle>Terms &amp; Conditions</DialogTitle>
                    <DialogContent>
                      <List className="bd__tnc-list">
                        {tncList?.map((d, i) => (
                          <div>
                            <Typography
                              sx={{
                                marginBottom: "-1%",
                                flexShrink: 0,
                              }}
                            >
                              {d.name}
                            </Typography>
                            <ListItem>
                              <ListItemText primary={d.description} />
                            </ListItem>
                          </div>
                        ))}
                      </List>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseTermsCondition}>
                        Accept &amp; Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className="bd__tnc">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={handleRentAgreementChange}
                    />
                  </FormGroup>
                  <FormLabel>
                    I have read and agree to all the clauses mentioned in the{" "}
                    <span onClick={handleRentAgreement}>Rent Agreement.</span>
                  </FormLabel>
                  <Dialog
                    fullWidth={true}
                    open={openRentAgreement}
                    onClose={handleCloseRentAgreement}
                    maxWidth="lg"
                  >
                    <DialogTitle>Rent Agreement</DialogTitle>
                    <DialogContent>
                      <List className="bd__tnc-list">
                        <div>
                          <iframe
                            src="/images/Student_Rent_Agreement.pdf"
                            width="100%"
                            height="500px"
                          />
                        </div>
                      </List>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseRentAgreement}>
                        Accept &amp; Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <Button
                  id="paynbookbtn"
                  variant="contained"
                  disabled={payAndBookDisabled}
                  onClick={handlePayAndBook}
                >
                  Book Now
                </Button>
              </section>
            </main>
            <Dialog open={openPaymentGateway}>
              <DialogContent>
                {bookingInProgress ? (
                  <div className="bip__main">
                    <CircularProgress />
                    <h3>Booking your second home...</h3>
                    <p>Please do not close or refresh the page.</p>
                  </div>
                ) : (
                  ""
                )}
                <div id="paymentForm"></div>
              </DialogContent>
            </Dialog>
          </Container>
        )}

        <Footer />
      </div>

      {/*start code for mobile number verification  */}
      <Dialog
        maxWidth="lg"
        open={openOtpScreen}
        // onClose={handleCloseVerifyMobile}
      >
        <form onSubmit={handleVerifyMobileOtp}>
          <DialogTitle>Verify Mobile Number</DialogTitle>
          <DialogContent>
            <div className="up__change-popup">
              <h4>Enter OTP sent on your mobile number</h4>
              <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Alert
                  onClose={handleSnackBarClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMsg}
                </Alert>
              </Snackbar>
              <div className="auth__step2-otp">
                <TextField
                  className="component__textbox-otp mobileotp1"
                  onChange={(e) => handleMobileOtp1(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  autoFocus={true}
                  value={mobileotp1}
                />
                <TextField
                  className="component__textbox-otp mobileotp2"
                  onChange={(e) => handleMobileOtp2(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp2}
                />
                <TextField
                  className="component__textbox-otp mobileotp3"
                  onChange={(e) => handleMobileOtp3(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp3}
                />
                <TextField
                  className="component__textbox-otp mobileotp4"
                  onChange={(e) => handleMobileOtp4(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp4}
                />

                <TextField
                  className="component__textbox-otp mobileotp5"
                  onChange={(e) => handleMobileOtp5(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp5}
                />

                <TextField
                  className="component__textbox-otp mobileotp6"
                  onChange={(e) => handleMobileOtp6(e.target.value)}
                  required
                  variant="outlined"
                  type="text"
                  value={mobileotp6}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVerifyMobile}>Close</Button>
            <Button variant="outlined" type="submit">
              Verify
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*end code for mobile number verification */}

      <Snackbar
        open={bookError}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Booking Failed! Retry again.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mobileVerifiedError}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Before Booking, Please verify your mobile number {userData?.mobileNo}.
          <Button onClick={handleGetMobileOtp}>Click here</Button>
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
